import { firestore } from "../../config/firebase";
import { addDoc, collection } from "firebase/firestore";

const createNotification = async (obj) => {
  const notificationsCollection = collection(firestore, "notifications");
  try{
    await addDoc(notificationsCollection, obj);
    console.log("done");
  }catch(e){
    console.error("Cannot create notification: ", e)
  }
};

export default createNotification;
