import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../config/firebase";

const getInvestmentPromos = async () => {
    const investmentPromosCollection = collection(firestore, "investmentPromos");
    const q = query(investmentPromosCollection, where("isActive", "==", true));
    const querySnapshot = await getDocs(q);

    const promosData = querySnapshot.docs.map((data) => {
        var promoObj = data.data();
        promoObj['id'] = data.id;
        return promoObj
    });
    
    return promosData;
}
export default getInvestmentPromos;