import { useState } from "react";
import CustomInput from "../../../components/CustomInput";
import CustomButton from "../../../components/CustomButton";
import { REGIONS } from "../../../helpers/regions";
import { NATIONALITIES } from "../../../helpers/nationalities";
import { INCOME } from "../../../helpers/income";
import BankAccountForm from "./BankAccountForm";
import generateId from "../../../utils/generateId";
import CustomDropdown from "../../../components/CustomDropdown";
import { COUNTRY_KEYS } from "../../../helpers/country_keys";
import { isUserInfoValid } from "../../../utils/inputValidations";
import { isPhoneNumberExist } from "../../../utils/dataValidations";
import { useNavigate } from "react-router-dom";
import updateUser from "../../../lib/users/updateUser";

export default function EditUserForm({ userInfo }) {
  const navigate = useNavigate();
  const [fullName, setFullName] = useState(userInfo.name);
  const [phoneNumber, setPhoneNumber] = useState(userInfo.phone.substring(4, userInfo.phone.length));
  const [countryCode, setCountryCode] = useState(userInfo.phone.substring(0, 4));
  const [city, setCity] = useState(userInfo.city);
  const [nationality, setNationality] = useState(userInfo.nationality);
  const [income, setIncome] = useState(userInfo.income);
  const [nationalId, setNationalId] = useState(userInfo.nationalId ?? "");
  const [email, setEmail] = useState(userInfo.emailAddress ?? "");
  const [bankAccounts, setBankAccounts] = useState(userInfo.bankAccounts);
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
      try{
        if(!isUserInfoValid(fullName, phoneNumber)){
          setIsLoading(false);
          return;
        }else if(await isPhoneNumberExist(`${countryCode}${phoneNumber}`, userInfo.id)){
          setIsLoading(false);
          return;
        }else{
          const userObj = {
            name: fullName,
            phone: `${countryCode}${phoneNumber}`,
            city: city,
            nationality: nationality,
            income: income,
            nationalId: nationalId,
            emailAddress: email,
            bankAccounts: bankAccounts,
          };
          await updateUser(userInfo.id, userObj);
          navigate(0);
        }
        
      }catch(e){
        setIsLoading(false);
        console.log(e);
      }
      setIsLoading(false);
  }
  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <div>
      <div>
        <label className="text-text-2-color font-bold">الإسم كاملا</label>
        <CustomInput
          type="text"
          value={fullName}
          setValue={setFullName}
          placeholder="ادخل الإسم كاملاً"
          customStyle="mt-5 mb-5"
          isRequired={true}
        />
      </div>
      <div>
        <label className="text-text-2-color font-bold">رقم الجوال</label>
        <div className="flex gap-5">
        <CustomDropdown
            options={COUNTRY_KEYS}
            customStyle="mt-5 mb-5"
            style={{ maxWidth: 100 }}
            defaultValue={countryCode}
            setValue={setCountryCode}
            listName="countryCodes"
            isRequired={true}
          />
          <CustomInput
            type="number"
            value={phoneNumber}
            setValue={setPhoneNumber}
            placeholder="5XXXXXXXX"
            customStyle="mt-5 mb-5 w-full"
            isRequired={true}
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-10">
        <div>
          <label className="text-text-2-color font-bold">المدينة</label>
          <CustomDropdown
            options={REGIONS}
            customStyle="mt-5 mb-5"
            setValue={setCity}
            defaultValue={city}
            listName="regions"
            isRequired={true}
          />
        </div>
        <div>
          <label className="text-text-2-color font-bold">الجنسية</label>
          <CustomDropdown
            options={NATIONALITIES}
            customStyle="mt-5 mb-5"
            setValue={setNationality}
            defaultValue={nationality}
            listName="nationalities"
            isRequired={true}
          />
        </div>
      </div>
      <div>
        <label className="text-text-2-color font-bold">الدخل الشهري</label>
        <CustomDropdown
          options={INCOME}
          customStyle="mt-5 mb-5"
          setValue={setIncome}
          defaultValue={income}
          listName="income"
          isRequired={true}
        />
      </div>
      <div>
        <label className="text-text-2-color font-bold">الهوية الوطنية / الإقامة</label>
        <CustomInput
            type="number"
            value={nationalId}
            setValue={setNationalId}
            placeholder="ادخل رقم الهوية أو الإقامة..."
            customStyle="mt-5 mb-5 w-full"
            isRequired={false}
          />
      </div>
      <div>
        <label className="text-text-2-color font-bold">البريد الإلكتروني</label>
        <CustomInput
            type="email"
            value={email}
            setValue={setEmail}
            placeholder="ادخل البريد الإلكتروني..."
            customStyle="mt-5 mb-5 w-full"
            isRequired={false}
          />
      </div>
      {bankAccounts.map((input, index) => {
        return (
          <BankAccountForm
            input={input}
            index={index}
            inputFields={bankAccounts}
            setInputFields={setBankAccounts}
          />
        );
      })}
      <div className="grid grid-cols-3">
        <CustomButton
          type="outline"
          title="إضافة حساب بنكي جديد"
          customStyle="p-4 mt-5 mb-24 w-48"
          handleClick={() => {
            if (bankAccounts.length < 5) {
              let newFields = { id: generateId(), beneficiary: "", bank: "ALINMA", IBAN: "" };
              setBankAccounts([...bankAccounts, newFields]);
            }
          }}
        />
      </div>
      <CustomButton title="تعديل" customStyle="p-5" isLoading={isLoading} />
    </div>
    </form>
  );
}
