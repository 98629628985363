import { collection, getCountFromServer, query, where } from "firebase/firestore";
import { firestore } from "../../config/firebase";

const getInvestmentsCountByCampaignId = async(campaignId, status) => {
    const investmentsCollection = collection(firestore, "investments");
    const q = query(investmentsCollection, where("campaignId", "==", campaignId), where("status", "==", status))
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
}

export default getInvestmentsCountByCampaignId;