export const NATIONALITIES = {
  Saudi: "سعودي",
  Afghan: "أفغاني",
  Albanian: "ألباني",
  Algerian: "جزائري",
  American: "أمريكي",
  Andorran: "أندوري",
  Angolan: "أنغولي",
  Antiguans: "انتيغوا",
  Argentinean: "أرجنتيني",
  Armenian: "أرميني",
  Australian: "أسترالي",
  Austrian: "نمساوي",
  Azerbaijani: "أذربيجاني",
  Bahamian: "باهامى",
  Bahraini: "بحريني",
  Bangladeshi: "بنجلاديشي",
  Barbadian: "باربادوسي",
  Barbudans: "بربودا",
  Batswana: "بوتسواني",
  Belarusian: "بيلاروسي",
  Belgian: "بلجيكي",
  Belizean: "بليزي",
  Beninese: "بنيني",
  Bhutanese: "بوتاني",
  Bolivian: "بوليفي",
  Bosnian: "بوسني",
  Brazilian: "برازيلي",
  British: "بريطاني",
  Bruneian: "بروناى",
  Bulgarian: "بلغاري",
  Burkinabe: "بوركيني",
  Burmese: "بورمي",
  Burundian: "بوروندي",
  Cambodian: "كمبودي",
  Cameroonian: "كاميروني",
  Canadian: "كندي",
  Cape: "االرأس الأخضر",
  Central_African: "وسط أفريقيا",
  Chadian: "تشادي",
  Chilean: "شيلي",
  Chinese: "صينى",
  Colombian: "كولومبي",
  Comoran: "جزر القمر",
  Congolese: "كونغولي",
  Costa_Rican: "كوستاريكي",
  Croatian: "كرواتية",
  Cuban: "كوبي",
  Cypriot: "قبرصي",
  Czech: "تشيكي",
  Danish: "دانماركي",
  Djibouti: "جيبوتي",
  Dominican: "دومينيكاني",
  Dutch: "هولندي",
  East_Timorese: "تيموري شرقي",
  Ecuadorean: "اكوادوري",
  Egyptian: "مصري",
  Emirian: "إماراتي",
  Equatorial_Guinean: "غيني  استوائي",
  Eritrean: "إريتري",
  Estonian: "إستوني",
  Ethiopian: "حبشي",
  Fijian: "فيجي",
  Filipino: "فلبيني",
  Finnish: "فنلندي",
  French: "فرنسي",
  Gabonese: "جابوني",
  Gambian: "غامبيي",
  Georgian: "جورجي",
  German: "ألماني",
  Ghanaian: "غاني",
  Greek: "إغريقي",
  Grenadian: "جرينادي",
  Guatemalan: "غواتيمالي",
  Guinea_Bissauan: "غيني بيساوي",
  Guinean: "غيني",
  Guyanese: "جوياني",
  Haitian: "هايتي",
  Herzegovinian: "هرسكي",
  Honduran: "هندوراسي",
  Hungarian: "هنغاري",
  Icelander: "إيسلندي",
  Indian: "هندي",
  Indonesian: "إندونيسي",
  Iranian: "إيراني",
  Iraqi: "عراقي",
  Irish: "إيرلندي",
  Italian: "إيطالي",
  Ivorian: "إفواري",
  Jamaican: "جامايكي",
  Japanese: "ياباني",
  Jordanian: "أردني",
  Kazakhstani: "كازاخستاني",
  Kenyan: "كيني",
  Kittian_and_Nevisian: "كيتياني ونيفيسياني",
  Kuwaiti: "كويتي",
  Kyrgyz: "قيرغيزستان",
  Laotian: "لاوسي",
  Latvian: "لاتفي",
  Lebanese: "لبناني",
  Liberian: "ليبيري",
  Libyan: "ليبي",
  Liechtensteiner: "ليختنشتايني",
  Lithuanian: "لتواني",
  Luxembourger: "لكسمبرغي",
  Macedonian: "مقدوني",
  Malagasy: "مدغشقري",
  Malawian: "مالاوى",
  Malaysian: "ماليزي",
  Maldivan: "مالديفي",
  Malian: "مالي",
  Maltese: "مالطي",
  Marshallese: "مارشالي",
  Mauritanian: "موريتاني",
  Mauritian: "موريشيوسي",
  Mexican: "مكسيكي",
  Micronesian: "ميكرونيزي",
  Moldovan: "مولدوفي",
  Monacan: "موناكو",
  Mongolian: "منغولي",
  Moroccan: "مغربي",
  Mosotho: "ليسوتو",
  Motswana: "لتسواني",
  Mozambican: "موزمبيقي",
  Namibian: "ناميبي",
  Nauruan: "ناورو",
  Nepalese: "نيبالي",
  New_Zealander: "نيوزيلندي",
  Ni_Vanuatu: "ني فانواتي",
  Nicaraguan: "نيكاراغوا",
  Nigerien: "نيجري",
  North_Korean: "كوري شمالي",
  Northern_Irish: "إيرلندي شمالي",
  Norwegian: "نرويجي",
  Omani: "عماني",
  Pakistani: "باكستاني",
  Palauan: "بالاوي",
  Palestinian: "فلسطيني",
  Panamanian: "بنمي",
  Papua_New_Guinean: "بابوا غينيا الجديدة",
  Paraguayan: "باراغواياني",
  Peruvian: "بيروفي",
  Polish: "بولندي",
  Portuguese: "برتغالي",
  Qatari: "قطري",
  Romanian: "روماني",
  Russian: "روسي",
  Rwandan: "رواندي",
  Saint_Lucian: "لوسياني",
  Salvadoran: "سلفادوري",
  Samoan: "ساموايان",
  San_Marinese: "سان مارينيز",
  Sao_Tomean: "ساو توميان",
  Scottish: "سكتلندي",
  Senegalese: "سنغالي",
  Serbian: "صربي",
  Seychellois: "سيشلي",
  Sierra_Leonean: "سيرا ليوني",
  Singaporean: "سنغافوري",
  Slovakian: "سلوفاكي",
  Slovenian: "سلوفيني",
  Solomon_Islander: "جزر سليمان",
  Somali: "صومالي",
  South_African: "جنوب افريقيي",
  South_Korean: "كوري جنوبي",
  Spanish: "إسباني",
  Sri_Lankan: "سري لانكي",
  Sudanese: "سوداني",
  Surinamer: "سورينامي",
  Swazi: "سوازي",
  Swedish: "سويدي",
  Swiss: "سويسري",
  Syrian: "سوري",
  Taiwanese: "تايواني",
  Tajik: "طاجيكي",
  Tanzanian: "تنزاني",
  Thai: "التايلاندي",
  Togolese: "توغواني",
  Tongan: "تونجاني",
  Trinidadian: "ترينيدادي أو توباغوني",
  Tunisian: "تونسي",
  Turkish: "تركي",
  Tuvaluan: "توفالي",
  Ugandan: "أوغندي",
  Ukrainian: "أوكراني",
  Uruguayan: "أوروجواي",
  Uzbekistani: "أوزبكستاني",
  Venezuelan: "فنزويلي",
  Vietnamese: "فيتنامي",
  Welsh: "ويلزي",
  Yemenite: "يمني",
  Zambian: "زامبي",
  Zimbabwean: "زيمبابوي",
};
