export function sortList(array, propertyName) {
    return array.sort((a, b) => {
      // Custom sorting function
      const propertyA = a[propertyName] || false;
      const propertyB = b[propertyName] || false;
  
      // Sorting logic
      if (propertyA === propertyB) {
        // If property values are equal, maintain the existing order
        return 0;
      } else if (propertyA) {
        // If property is true for A, place it after B
        return 1;
      } else {
        // If property is false or undefined for A, place it before B
        return -1;
      }
    });
  }