import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../config/firebase";

const getInvestmentPromosUsageByCodeId = async (id) => {
    const invPromosUsageCollection = collection(firestore, "investmentPromosUsage");
    const q = query(invPromosUsageCollection, where("codId", "==", id));
    const querySnapshot = await getDocs(q);

    const promosData = querySnapshot.docs.map((data) => {
        var promoObj = data.data();
        promoObj['id'] = data.id;
        return promoObj
    });
    
    return promosData;
}
export default getInvestmentPromosUsageByCodeId;