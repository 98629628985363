import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import PageLoader from '../../components/PageLoader';
import Navbar from '../../components/Navbar';
import PageTitle from '../../components/PageTitle';
import CustomButton from '../../components/CustomButton';
import getInvestmentPromoCodeById from '../../lib/investment_promos/getInvestmentPromoCodeById';
import getInvestmentPromosUsageByCodeId from '../../lib/investment_promos/getInvestmentPromosUsageByCodeId';
import getInvestmentById from '../../lib/investments/getInvestmentById';
import getCampaignById from '../../lib/campaigns/getCampaignById';
import InvestmentStatus from './components/InvestmentStatus';
import getUserById from '../../lib/users/getUserById';
import { formatDateNow } from "../../utils/formatDateNow";
import { exportExcelData } from "../../utils/exportExcelData";

export default function InvestmentPromoUsages() {
  const { promoId } = useParams();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [promo, setPromo] = useState({});
  const [promosUsage, setPromosUsage] = useState([]);
  const [promoInvestments, setPromoInvestments] = useState([]);
  const [promoCampaigns, setPromoCampaigns] = useState([]);
  const [promoUsers, setPromoUsers] = useState([]);
  const tableHeaders = [
    "اسم المستخدم",
    "رقم الجوال",
    "الفرصة",
    "عدد الوحدات",
    "قيمة الوحدة (ريال)",
    "قيمة الإستثمار (ريال)",
    "التاريخ",
    "حالة الإستثمار"
  ];
  useEffect(() => {
    const fetchData = async () => {
      // Promo info
      const fetchedPromo = await getInvestmentPromoCodeById(promoId);
      setPromo(fetchedPromo);

      // Promo usage
      const fetchedPromosUsage = await getInvestmentPromosUsageByCodeId(promoId);
      setPromosUsage(fetchedPromosUsage);

      // Campaigns of promos
      let tempCampaigns = [];
      for(var i = 0; i < fetchedPromosUsage.length; i++){
        var campaign = await getCampaignById(fetchedPromosUsage[i].campaignId);
        tempCampaigns.push(campaign);
      }
      setPromoCampaigns(tempCampaigns);
      
      // Investments of promos
      let tempInvestments = [];
      for(var j = 0; j < fetchedPromosUsage.length; j++){
        var investment = await getInvestmentById(fetchedPromosUsage[j].investmentId);
        tempInvestments.push(investment);
      }
      setPromoInvestments(tempInvestments);

      // Users promo
      let tempUsers = [];
      for(var k = 0; k < fetchedPromosUsage.length; k++){
        var user = await getUserById(fetchedPromosUsage[k].userId);
        tempUsers.push(user);
      }
      setPromoUsers(tempUsers);

      setIsPageLoading(false);
    };
    fetchData();

  }, [promoId]);

  const handleOnExport = () => {
    let usersData = [];
    for (var i = 0; i < promosUsage.length; i++) {
      const userObj = {
        "اسم المستخدم": promoUsers[i].name,
        "رقم الجوال": promoUsers[i].phone,
        "الفرصة": promoCampaigns[i].title,
        "عدد الوحدات": promoInvestments[i].requestedSharesNumber,
        "قيمة الوحدة (ريال)": promoInvestments[i].campaignSharePrice.toLocaleString(),
        "قيمة الإستثمار (ريال)": (promoInvestments[i].campaignSharePrice * promoInvestments[i].requestedSharesNumber).toLocaleString(),
        "التاريخ": promosUsage[i].createdAt.toDate().toISOString().split("T")[0],
        "حالة الإستثمار": promoInvestments[i].status === "APPROVED" 
        ? "تمت الموافقة" 
        : promoInvestments[i].status === "PENDING" 
        ? "بانتظار الموافقة" 
        : promoInvestments[i].status === "REJECTED" 
        ? "مرفوض" 
        : "محذوف",
      };
      usersData.push(userObj);
    }
    const now = formatDateNow();
    exportExcelData(usersData, "Usage", `${promo.code}_Usage_${now}.xlsx`);
  };

  const approvedInvestments = promoInvestments.filter(item => item.status === "APPROVED");

  return isPageLoading 
  ? (<PageLoader />)
  : (
    <div>
      <Navbar />
      <PageTitle title={`سجل الكود الترويجي للمسجلين ${promo.code}`} />
      <div className="bg-ice-color p-5 text-center rounded-lg mt-5 mb-10">
        <p className="text-text-1-color font-bold text-lg mb-5">
          عدد استخدامات الكود
        </p>
        <p className="text-primary-color text-2xl">{promosUsage.length.toLocaleString()}</p>
      </div>
      <div className='flex flex-row gap-5'>
      <div className="bg-ice-color p-5 text-center rounded-lg mt-5 mb-10 w-full">
        <p className="text-text-1-color font-bold text-lg mb-5">
          عدد استخدامات الكود وتم الموافقة على الاستثمار
        </p>
        <p className="text-primary-color text-2xl">{approvedInvestments.length.toLocaleString()}</p>
      </div>
      <div className="bg-ice-color p-5 text-center rounded-lg mt-5 mb-10 w-full">
        <p className="text-text-1-color font-bold text-lg mb-5">
          عدد استخدامات الكود ولم يتم الموافقة على الاستثمار
        </p>
        <p className="text-primary-color text-2xl">{(promoInvestments.length - approvedInvestments.length).toLocaleString()}</p>
      </div>
      </div>
      <div className="flex flex-row justify-between items-center mb-16">
        <PageTitle title="السجل" />
        <div className="w-60">
          <CustomButton
            title="استخراج اكسل"
            customStyle="p-2 mb-5"
            handleClick={handleOnExport}
          />
        </div>
      </div>
      <div className="overflow-x-auto">
      <table className="w-full text-sm">
        <thead className="text-xs text-white uppercase bg-primary-color font-bold text-center">
          <tr>
            {tableHeaders.map((header, index) => {
              return (
                <th scope="col" className="px-6 py-3" key={index}>
                  {header}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="text-center">
          {promosUsage.map((column, index) => {
            return (
              <tr key={index} className="bg-white text-text-1-color font-semibold odd:bg-white even:bg-ice-color">
                {
                  <>
                  <td className="px-6 py-4">{promoUsers[index].name}</td>
                  <td className="px-6 py-4" dir="ltr">{promoUsers[index].phone}</td>
                  <td className="px-6 py-4">{promoCampaigns[index].title}</td>
                  <td className="px-6 py-4">{promoInvestments[index].requestedSharesNumber}</td>
                  <td className="px-6 py-4">{promoInvestments[index].campaignSharePrice.toLocaleString()}</td>
                  <td className="px-6 py-4">{(promoInvestments[index].campaignSharePrice * promoInvestments[index].requestedSharesNumber).toLocaleString()}</td>
                  <td className="px-6 py-4">{column.createdAt.toDate().toISOString().split("T")[0]}</td>
                  <td className="px-6 py-4"><InvestmentStatus status={promoInvestments[index].status} /></td>
                  </>
                }
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
    </div>
  );
}
