import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../config/firebase";

const getRegistrationPromosUsageByCodeId = async (id) => {
    const regPromosUsageCollection = collection(firestore, "registrationPromosUsage");
    const q = query(regPromosUsageCollection, where("codId", "==", id));
    const querySnapshot = await getDocs(q);

    const promosData = querySnapshot.docs.map((data) => {
        var promoObj = data.data();
        promoObj['id'] = data.id;
        return promoObj
    });
    
    return promosData;
}
export default getRegistrationPromosUsageByCodeId;