import { useState } from "react";

export default function UploadButton({ imageFiles, setImageFiles }) {
  const [displayedImages, setDisplayedImages] = useState([]);
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImageFiles([
        ...imageFiles,
        event.target.files[0],
      ]);
      setDisplayedImages([
        ...displayedImages,
        URL.createObjectURL(event.target.files[0])
      ]);
    }

    console.log(imageFiles);
  };

  const onImageRemove = (index) => {
    const updatedImageFiles = imageFiles.filter((_, i) => i !== index);
    const updatedDisplayedImages = displayedImages.filter((_, i) => i !== index);
    setImageFiles(updatedImageFiles);
    setDisplayedImages(updatedDisplayedImages);
  };
  return (
    <>
      {displayedImages.map((data, index) => {
        return (
          <div className="flex items-center justify-center w-full" key={index}>
            <label
            htmlFor={`remove-image-${index}`}
              className="flex flex-col items-center justify-center w-full h-128 rounded-3xl cursor-pointer bg-box-color p-5"
            >
              <img
                  alt="img"
                  src={data}
                  className="object-contain h-full w-full hover:opacity-50"
                />
            </label>
            <input
            id={`remove-image-${index}`}
            type="button"
            className="hidden"
            onClick={() => onImageRemove(index)}
          />
          </div>
        );
      })}
      <div className="flex items-center justify-center w-full">
        <label
          htmlFor="image-file"
          className="flex flex-col items-center justify-center w-full h-128 rounded-3xl cursor-pointer bg-box-color p-5 hover:bg-text-1-color"
        >
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <svg
              className="w-20 h-20 mb-4 text-text-3-color"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 16"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
              />
            </svg>
          </div>
          <input
            id="image-file"
            type="file"
            className="hidden"
            onChange={onImageChange}
          />
        </label>
      </div>
    </>
  );
}
