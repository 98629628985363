import { collection, getCountFromServer, query, where } from "firebase/firestore";
import { firestore } from "../../config/firebase";

const getCampaignsCount = async(status) => {
    const campaignsCollection = collection(firestore, "campaigns");
    const q = query(campaignsCollection, where("status", "==", status))
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
}

export default getCampaignsCount;