import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../config/firebase";
import notify from "./notify";

export const isPhoneNumberExist = async (number, id) => {
    const usersCollection = collection(firestore, "users");
    const q = query(usersCollection, where("phone", "==", number.toString()));

    const querySnapshot = await getDocs(q);
    const uid = querySnapshot.docs.map((doc) => doc.id)[0];

    if(querySnapshot.size > 0 && uid !== id){
        notify("ERROR", "هذا الرقم مسجل في النظام.")
        return true;
    }

    return false;
}