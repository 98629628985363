import { firestore } from "../../config/firebase";
import { addDoc, collection } from "firebase/firestore";

const createCampaign = async (obj) => {
  const campaignsCollection = collection(firestore, "campaigns");
  try{
    await addDoc(campaignsCollection, obj);
  }catch(e){
    console.error("Cannot create campaign: ", e)
  }
};

export default createCampaign;
