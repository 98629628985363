import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/PageTitle";
import CustomButton from "../../components/CustomButton";
import getReportsByCampaignId from "../../lib/reports/getReportsByCampaignId";
import PageLoader from "../../components/PageLoader";
import CustomInput from "../../components/CustomInput";
import CustomModal from "../../components/CustomModal";
import CustomTextArea from "../../components/CustomTextArea";
import createReport from "../../lib/reports/createReport";
import { Timestamp } from "firebase/firestore";
import notify from "../../utils/notify";
import { ToastContainer } from "react-toastify";
import updateReport from "../../lib/reports/updateReport";
import { useNavigate } from "react-router-dom";
import getInvestments from "../../lib/investments/getInvestments";
import createNotification from "../../lib/notifications/createNotification";

export default function GeneralReportsPage() {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [showConfirmationModal, setShowConfirmaionModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState({});
  const [reports, setReports] = useState([]);
  const navigate = useNavigate();
  const tableHeaders = ["عنوان التقرير", "تاريخ التقرير", "إجراءات"];

  // Inputs
  const [title, setTitle] = useState("");
  const [averageOccupancy, setAverageOccupancy] = useState(0);
  const [averageMonthlyEarnings, setAverageMonthlyEarnings] = useState(0);
  const [averageNightRate, setAverageNightRate] = useState(0);
  const [totalProfitPerShares, setTotalProfitPerShares] = useState(0);
  const [returnPercentage, setReturnPercentage] = useState(0);
  const [moreDetails, setMoreDetails] = useState("");

  useEffect(() => {
    const fetchGeneralReports = async () => {
      const fetchedReports = await getReportsByCampaignId("GENERAL");
      setReports(fetchedReports);
      setIsPageLoading(false);
    };

    fetchGeneralReports();
  }, []);

  const handleDelete = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await updateReport(selectedReport.id, { status: "DELETED" });
      navigate(0);
    } catch (e) {
      setIsLoading(false);
      notify("ERROR", "حصل خطأ.");
      console.error("Cannot delete report: ", e);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await createReport({
        campaignId: "GENERAL",
        title: title,
        avgOccupancy: Number(averageOccupancy),
        avgMonthlyEarnings: Number(averageMonthlyEarnings),
        status: "ACTIVE",
        avgNightRate: Number(averageNightRate),
        totalProfitPerShares: Number(totalProfitPerShares),
        returnPercentage: Number(returnPercentage),
        highestRatePerNight: Number(0),
        lowestRatePerNight: Number(0),
        moreDetails: moreDetails,
        createdAt: Timestamp.now(),
      });

      // Get approvedInvestments
      const approvedInvestments = await getInvestments("APPROVED");

      // Create a Set to keep track of unique user IDs
      const uniqueUserIds = new Set();
      

      approvedInvestments.forEach(async (investment, index) => {
        if (!uniqueUserIds.has(investment.userId)) {
          uniqueUserIds.add(investment.userId);

          await createNotification({
            title: title,
            message: `اعرض التقرير في صفحة التقارير`,
            data: { type: "ONLY_INVESTORS_REPORT", campaignId: "GENERAL" },
            toUser: investment.userId,
            isRead: false,
            createdAt: Timestamp.now(),
          });
        }

        if (approvedInvestments.length === index + 1) {
          navigate(0);
        }
      });
    } catch (e) {
      setIsLoading(false);
      notify("ERROR", "حصل خطأ.");
      console.error("Error: ", e);
    }
  };
  return isPageLoading ? (
    <PageLoader />
  ) : (
    <div>
      <ToastContainer />
      <Navbar />
      <div className="flex flex-row justify-between items-center mb-16">
        <PageTitle title="التقارير" />
        <div className="w-60">
          <CustomButton
            title="إضافة تقرير"
            customStyle="p-3"
            handleClick={() => setShowModal(true)}
          />
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="w-full text-sm">
          <thead className="text-xs text-white uppercase bg-primary-color font-bold text-center">
            <tr>
              {tableHeaders.map((header, index) => {
                return (
                  <th scope="col" className="px-6 py-3" key={index}>
                    {header}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="text-center">
            {reports.map((column, index) => {
              return (
                <tr
                  key={index}
                  className="bg-white text-text-1-color font-semibold odd:bg-white even:bg-ice-color"
                >
                  {
                    <>
                      <td className="px-6 py-4">{column.title}</td>
                      <td className="px-6 py-4">
                        {column.createdAt.toDate().toISOString().split("T")[0]}
                      </td>
                      <td className="px-6 py-4">
                        <div className="flex flex-row gap-5">
                          <CustomButton
                            title="عرض"
                            customStyle="px-8 py-2"
                            handleClick={() => {
                              setShowViewModal(true);
                              setSelectedReport(column);
                            }}
                          />
                          <CustomButton
                            title="حذف"
                            type="danger"
                            customStyle="px-8 py-2"
                            handleClick={() => {
                              setShowConfirmaionModal(true);
                              setSelectedReport(column);
                            }}
                          />
                        </div>
                      </td>
                    </>
                  }
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <CustomModal
        showModal={showModal}
        setShowModal={setShowModal}
        title="إضافة تقرير"
        height="h-156"
        content={
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="grid grid-cols-2 gap-10">
              <div>
                <label className="text-text-2-color font-bold">
                  عنوان التقرير
                </label>
                <CustomInput
                  type="text"
                  value={title}
                  setValue={setTitle}
                  placeholder="ادخل عنوان للتقرير"
                  customStyle="mt-5 mb-5"
                  isRequired={true}
                />
              </div>
              <div>
                <label className="text-text-2-color font-bold">
                  متوسط الإشغال (%)
                </label>
                <CustomInput
                  type="number"
                  value={averageOccupancy}
                  setValue={setAverageOccupancy}
                  placeholder="ادخل متوسط الإشغال..."
                  customStyle="mt-5 mb-5"
                  isRequired={true}
                />
              </div>
              <div>
                <label className="text-text-2-color font-bold">
                  متوسط الأرباح الشهرية (ريال)
                </label>
                <CustomInput
                  type="number"
                  value={averageMonthlyEarnings}
                  setValue={setAverageMonthlyEarnings}
                  placeholder="متوسط الأرباح الشهرية (ريال)..."
                  customStyle="mt-5 mb-5"
                  isRequired={true}
                />
              </div>
              <div>
                <label className="text-text-2-color font-bold">
                  متوسط سعر الليلة (ريال)
                </label>
                <CustomInput
                  type="text"
                  value={averageNightRate}
                  setValue={setAverageNightRate}
                  placeholder="ادخل متوسط سعر الليلة (ريال)..."
                  customStyle="mt-5 mb-10"
                  isRequired={true}
                />
              </div>
              <div>
                <label className="text-text-2-color font-bold">
                  إجمالي الربح لجميع الحصص (ريال)
                </label>
                <CustomInput
                  type="text"
                  value={totalProfitPerShares}
                  setValue={setTotalProfitPerShares}
                  placeholder="ادخل إجمالي الربح لجميع الحصص (ريال)..."
                  customStyle="mt-5 mb-10"
                  isRequired={true}
                />
              </div>
              <div>
                <label className="text-text-2-color font-bold">
                  نسبة العائد (%)
                </label>
                <CustomInput
                  type="text"
                  value={returnPercentage}
                  setValue={setReturnPercentage}
                  placeholder="ادخل نسبة العائد (%)..."
                  customStyle="mt-5 mb-10"
                  isRequired={true}
                />
              </div>
            </div>
            <label className="text-text-1-color font-bold text-xl">
              تفاصيل إضافية
            </label>
            <CustomTextArea
              value={moreDetails}
              setValue={setMoreDetails}
              placeholder="ادخل محتوى التفاصيل الإضافية..."
              customStyle="h-96 mt-5 mb-10"
              isRequired={true}
            />
            <CustomButton
              title="إضافة"
              customStyle="py-5"
              isLoading={isLoading}
            />
          </form>
        }
      />

      <CustomModal
        showModal={showViewModal}
        setShowModal={setShowViewModal}
        title="عرض التقرير"
        height="h-156"
        content={
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="grid grid-cols-2 gap-10">
              <div>
                <label className="text-text-2-color font-bold">
                  عنوان التقرير
                </label>
                <CustomInput
                  type="text"
                  value={selectedReport.title}
                  setValue={setTitle}
                  placeholder="ادخل عنوان للتقرير"
                  customStyle="mt-5 mb-5"
                  isRequired={true}
                  isDisabled={true}
                />
              </div>
              <div>
                <label className="text-text-2-color font-bold">
                  متوسط الإشغال (%)
                </label>
                <CustomInput
                  type="number"
                  value={selectedReport.avgOccupancy}
                  setValue={setAverageOccupancy}
                  placeholder="ادخل متوسط الإشغال..."
                  customStyle="mt-5 mb-5"
                  isRequired={true}
                  isDisabled={true}
                />
              </div>
              <div>
                <label className="text-text-2-color font-bold">
                  متوسط الأرباح الشهرية (ريال)
                </label>
                <CustomInput
                  type="number"
                  value={selectedReport.avgMonthlyEarnings}
                  setValue={setAverageMonthlyEarnings}
                  placeholder="متوسط الأرباح الشهرية (ريال)..."
                  customStyle="mt-5 mb-5"
                  isRequired={true}
                  isDisabled={true}
                />
              </div>
              <div>
                <label className="text-text-2-color font-bold">
                  متوسط سعر الليلة (ريال)
                </label>
                <CustomInput
                  type="text"
                  value={selectedReport.avgNightRate}
                  setValue={setAverageNightRate}
                  placeholder="ادخل متوسط سعر الليلة (ريال)..."
                  customStyle="mt-5 mb-10"
                  isRequired={true}
                  isDisabled={true}
                />
              </div>
              <div>
                <label className="text-text-2-color font-bold">
                  إجمالي الربح لجميع الحصص (ريال)
                </label>
                <CustomInput
                  type="text"
                  value={selectedReport.totalProfitPerShares}
                  setValue={setTotalProfitPerShares}
                  placeholder="ادخل إجمالي الربح لجميع الحصص (ريال)..."
                  customStyle="mt-5 mb-10"
                  isRequired={true}
                  isDisabled={true}
                />
              </div>
              <div>
                <label className="text-text-2-color font-bold">
                  نسبة العائد (%)
                </label>
                <CustomInput
                  type="text"
                  value={selectedReport.returnPercentage}
                  setValue={setReturnPercentage}
                  placeholder="ادخل نسبة العائد (%)..."
                  customStyle="mt-5 mb-10"
                  isRequired={true}
                  isDisabled={true}
                />
              </div>
            </div>
            <label className="text-text-1-color font-bold text-xl">
              تفاصيل إضافية
            </label>
            <CustomTextArea
              value={selectedReport.moreDetails}
              setValue={setMoreDetails}
              placeholder="ادخل محتوى التفاصيل الإضافية..."
              customStyle="h-96 mt-5 mb-10"
              isRequired={true}
              isDisabled={true}
            />
          </form>
        }
      />

      <CustomModal
        showModal={showConfirmationModal}
        setShowModal={setShowConfirmaionModal}
        title="حذف التقرير"
        content={
          <div className="w-96 text-center">
            <p className="text-text-2-color mb-5 text-lg">
              هل أنت متأكد من حذف هذا التقرير؟
            </p>
            <p className="text-text-1-color mb-10">{selectedReport.title}</p>
            <div className="grid grid-cols-2 gap-10">
              <CustomButton
                title="حذف"
                customStyle="py-5"
                type="danger"
                isLoading={isLoading}
                handleClick={handleDelete}
              />
              <CustomButton
                title="رجوع"
                customStyle="py-5"
                type="outline"
                isLoading={isLoading}
                handleClick={() => setShowConfirmaionModal(false)}
              />
            </div>
          </div>
        }
      />
    </div>
  );
}
