import { collection, getDocs } from "firebase/firestore";
import { firestore } from "../../config/firebase";


const getUserByName = async (searchName) => {
    const usersCollection = collection(firestore, "users");

  const querySnapshot = await getDocs(usersCollection);

  const users = [];

  querySnapshot.forEach((doc) => {
    const userObj = doc.data();
    const userName = userObj.name.toLowerCase(); // Convert to lowercase for case-insensitive search

    if (userName === searchName.toLowerCase()) {
      userObj['id'] = doc.id;
      users.push(userObj);
    } else if (userName.includes(searchName.toLowerCase())) {
      userObj['id'] = doc.id;
      users.push(userObj);
    }
  });

  return users;
}

export default getUserByName;