import notify from "./notify";


// ------------------------------- User Info Inputs Validation -------------------------------
const isNameValid = (name) => {
  const cleanedInput = name.trim().toLowerCase();

  const digitRegex = /\d/;

  if (digitRegex.test(cleanedInput)) {
    return false;
  } else {
    return true;
  }
};

const isNumberValid = (number) => {
  if (number.startsWith("5") && number.length === 9) {
    return true;
  }
  return false;
};

export const isUserInfoValid = (name, number) => {
  if (!isNameValid(name)) {
    notify("WARNING", "الرجاء ادخال الإسم الكامل بشكل صحيح.")
    return false;
  }
  if (!isNumberValid(number)) {
    notify("WARNING", "الرجاء ادخال رقم الجوال بشكل صحيح.")
    return false;
  }
  return true;
};


