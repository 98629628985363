import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/PageTitle";
import CustomButton from "../../components/CustomButton";
import { INCOME } from "../../helpers/income";
import { NATIONALITIES } from "../../helpers/nationalities";
import { REGIONS } from "../../helpers/regions";
import getInvestments from "../../lib/investments/getInvestments";
import getUserById from "../../lib/users/getUserById";
import PageLoader from "../../components/PageLoader";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

export default function InvestorsManagementPage() {
  const [investors, setInvestors] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchInvestors = async () => {
      let users = [];
      const allInvestments = await getInvestments("APPROVED");
      for (const investment of allInvestments) {
        const userId = investment.userId;
        const user = await getUserById(userId);
        users.push(user);
      }
      setInvestors(users);
      setIsPageLoading(false);
    };
    fetchInvestors();
  }, []);
  const tableHeaders = [
    "اسم المستخدم",
    "رقم الجوال",
    "المدينة",
    "الجنسية",
    "الدخل الشهري",
    "حساب بنكي",
    "الحالة",
  ];
  return isPageLoading ? (
    <PageLoader />
  ) : (
    <div>
      <ToastContainer />
      <Navbar />
      <PageTitle title="إدارة المستثمرين" />
      <div className="flex flex-row gap-4 my-10 w-72">
        <CustomButton
          title="التقارير"
          customStyle="p-3"
          handleClick={() => navigate("/general_reports")}
        />
        <CustomButton
          title="الإشعارات"
          customStyle="p-3"
          handleClick={() => navigate("/notifications_management")}
        />
      </div>

      <div className="overflow-x-auto">
        <table className="w-full text-sm">
          <thead className="text-xs text-white uppercase bg-primary-color font-bold text-center">
            <tr>
              {tableHeaders.map((header, index) => {
                return (
                  <th scope="col" className="px-6 py-3" key={index}>
                    {header}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="text-center">
            {investors.map((column, index) => {
              return (
                <tr
                  key={index}
                  className="bg-white text-text-1-color font-semibold odd:bg-white even:bg-ice-color"
                >
                  {
                    <>
                      <td className="px-6 py-4">{column.name}</td>
                      <td className="px-6 py-4" dir="ltr">
                        {column.phone}
                      </td>
                      <td className="px-6 py-4">
                        {REGIONS["SA"][column.city]}
                      </td>
                      <td className="px-6 py-4">
                        {NATIONALITIES[column.nationality]}
                      </td>
                      <td className="px-6 py-4">{INCOME[column.income]}</td>
                      {column.bankAccounts.length > 0 ? (
                        <td className="px-6 py-4">يوجد</td>
                      ) : (
                        <td className="px-6 py-4">لا يوجد</td>
                      )}
                      {column.isBanned === true ? (
                        <td className="px-6 py-4">محظور</td>
                      ) : (
                        <td className="px-6 py-4">متاح</td>
                      )}
                    </>
                  }
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
