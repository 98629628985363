import { BANKS_NAMES } from "../../../helpers/banks";

export default function ViewBankAccountForm({ bankAccounts }) {
  console.log(bankAccounts);
  return (
    <div className="outline outline-1 outline-text-3-color rounded-3xl p-10 mt-10">
      {bankAccounts.map((data, index) => {
        return (
          <>
            <div className="grid grid-cols-2 justify-between">
              <p className="text-1-color font-bold mb-10">{`حساب بنكي #${index+1}`}</p>
            </div>

            <div className="mb-5">
              <p className="text-text-2-color font-bold mb-2">الاسم</p>
              <p className="bg-ice-color p-4 text-text-1-color">{data.beneficiary}</p>
            </div>

            <div className="mb-5">
              <p className="text-text-2-color font-bold mb-2">البنك</p>
              <p className="bg-ice-color p-4 text-text-1-color">{BANKS_NAMES[data.bank]}</p>
            </div>

            <div className="mb-5">
              <p className="text-text-2-color font-bold mb-2">رقم الآيبان</p>
              <p className="bg-ice-color p-4 text-text-1-color">{data.IBAN}</p>
            </div>
          </>
        );
      })}
    </div>
  );
}
