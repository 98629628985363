export const REGIONS = {
  SA: {
    Riyadh: "الرياض",
    Madina: "المدينة المنورة",
    Mecca: "مكة",
    Yanbu: "ينبع",
    Najran: "نجران",
    Taif: "الطائف",
    Jazan: "جازان",
    Jeddah: "جدة",
    Al_Khobar: "الخبر",
    Dammam: "الدمام",
    Dhahran: "الظهران",
    Ahad_Rafidah: "أحد رفيدة",
    Al_Aflaj: "الأفلاج",
    Al_Ahsa: "الأحساء",
    Al_Bahah: "الباحه",
    Al_Duwadimi: "الدوادمي",
    Al_Jubail: "الجبيل",
    Al_Kharj: "الخرج",
    Al_Qatif: "القطيف",
    Al_Qurayyat: "القريات",
    Ar_Rass: "الرس",
    Arar: "عرعر",
    Az_Zulfi: "الزلفي",
    Bahrah: "بحره",
    Beesh: "بيش",
    Bisha: "بيشه",
    Buraydah: "بريده",
    Duba: "ضباء",
    Hafar_Al_Batin: "حفر الباطن",
    Hail: "حائل",
    Hawtah: "الحوطة",
    Khamis_Mushait: "خميس مشيط",
    Sabya: "صبياء",
    Saihat: "سيهات",
    Sakaka: "سكاكا",
    Sharorah: "شروره",
    Tapuk: "تبوك",
    Tarout: "تاروت",
    Thuqbah: "الثقبة",
    Unaizah: "عنيزة",
    Wadi_ad_Dawasir: "وادي الدواسر",
    al_fraish: "الفريش",
    barq: "بارق",
  },
};
