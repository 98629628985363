import React from 'react'
import CustomButton from './CustomButton'
import { useAuth } from '../contexts/AuthContext'
import { useNavigate } from 'react-router-dom';

export default function Navbar() {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const handleLogout = async() => {
    try{
      await logout();
      navigate("/login");
    }catch(e){
      console.error("Cannot logout! ", e);
    }
  }
  return (
    <div className='flex flex-row justify-between items-center'>
        <img src='/logo.png' alt='logo' width={150} className='mb-10' onClick={() => navigate('/')} />
        <div className="w-60">
          <CustomButton title="تسجيل الخروج" customStyle="p-4" type="danger" handleClick={handleLogout} />
        </div>
    </div>
  )
}
