import React from "react";
import { RotatingLines } from "react-loader-spinner";

export default function PageLoader() {
  return (
    <div className="flex items-center justify-center h-screen">
        <RotatingLines
      strokeColor="#E7C067"
      strokeWidth="5"
      animationDuration="0.50"
      width="96"
      visible={true}
    />
    </div>
    
  );
}
