import React from "react";
import { ClipLoader } from "react-spinners";

export default function CustomButton({ title, customStyle, handleClick, type, isLoading }) {
  const color = type === "secondary" 
  ? "bg-secondary-color" 
  : type === "success" ? "bg-success-color text-white" 
  : type === "danger" ? "bg-danger-color text-white" 
  : type === "outline" ? "bg-transparent outline outline-1 outline-text-1-color text-text-1-color"
  : "bg-secondary-color text-white" 
  return (
    <button
    disabled={isLoading ? true : false}
    onClick={handleClick}
      className={`${customStyle} ${color} w-full rounded-full`}
    >
      {
        isLoading ? <ClipLoader color="#ffffff" size={"25"} /> : title
      }
    </button>
  );
}
