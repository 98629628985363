export const COUNTRY_KEYS = {
  SA: {
    country: "المملكة العربية السعودية",
    key: "+966",
  },
  UAE: {
    country: "الإمارات العربية المتحدة",
    key: "+971",
  },
  BH: {
    country: "البحرين",
    key: "+973",
  },

  KW: {
    country: "الكويت",
    key: "+965",
  },

  OM: {
    country: "عمان",
    key: "+963",
  },

  QA: {
    country: "قطر",
    key: "+974",
  },
};
