import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../config/firebase";


const getInvestmentById = async (id) => {
    const docRef = doc(firestore, "investments", id);
    const docSnap = await getDoc(docRef);

    if(docSnap.exists()){
        const invData = docSnap.data();
        invData["id"] = id;
        return invData;
    }else{
        console.log("No such document.")
    }
}

export default getInvestmentById;