import { firestore } from "../../config/firebase";
import { addDoc, collection } from "firebase/firestore";

const createRegistrationPromoCode = async (obj) => {
  const registrationPromoCollection = collection(firestore, "registrationPromos");
  try{
    await addDoc(registrationPromoCollection, obj);
    console.log("done");
  }catch(e){
    console.error("Cannot create promo code: ", e)
  }
};

export default createRegistrationPromoCode;
