import { collection, getDocs, query } from "firebase/firestore";
import { firestore } from "../../config/firebase";

const getCampaigns = async () => {
    const campaignsCollection = collection(firestore, "campaigns");
    const q = query(campaignsCollection);
    const querySnapshot = await getDocs(q);

    const campaignsData = querySnapshot.docs.map((data) => {
        var campObj = data.data();
        campObj['id'] = data.id;
        return campObj
    });
    
    return campaignsData;
}
export default getCampaigns;