import React from "react";

export default function DashboardItem({ title, value }) {
  return (
    <div className=" p-5 rounded-2xl bg-ice-color">
      <p className="text-text-1-color font-bold mb-8 text-lg">{title}</p>
      <p className="text-primary-color font-bold text-xl">{value}</p>
    </div>
  );
}
