import { firestore } from "../../config/firebase";
import { addDoc, collection, Timestamp } from "firebase/firestore";

const createUser = async (name, phone, city, nationality, income, nationalId, email, bankAccounts) => {
  const usersCollection = collection(firestore, "users");
  await addDoc(usersCollection, {
    name: name,
    phone: phone,
    city: city,
    nationality: nationality,
    income: income,
    nationalId: nationalId,
    emailAddress: email,
    bankAccounts: bankAccounts,
    isBanned: false,
    createdAt: Timestamp.now(),
  });
};

export default createUser;
