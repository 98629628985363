import { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/PageTitle";
import CustomButton from "../../components/CustomButton";
import CustomModal from "../../components/CustomModal";
import CustomInput from "../../components/CustomInput";
import CustomTextArea from "../../components/CustomTextArea";
import { useNavigate, useParams } from "react-router-dom";
import getInvestmentsByCampaignId from "../../lib/investments/getInvestmentsByCampaignId";
import INV_STATUS from "../../helpers/investment_status";
import updateInvestment from "../../lib/investments/updateInvestment";
import PageLoader from "../../components/PageLoader";
import notify from "../../utils/notify";
import getCampaignById from "../../lib/campaigns/getCampaignById";
import createNotification from "../../lib/notifications/createNotification";
import { ToastContainer } from "react-toastify";
import { Timestamp } from "firebase/firestore";

export default function InvestmentsRequestsPage() {
  const { campaignId } = useParams();
  const [investments, setInvestments] = useState([]);
  const [selectedInvestment, setSelectedInvestment] = useState({});
  const navigate = useNavigate();

  const [investorName, setInvestorName] = useState("");
  const [investorNumber, setInvestorNumber] = useState("");
  const [campaignSharePrice, setCampaignSharePrice] = useState(0);
  const [requestedSharesNumber, setRequestedSharesNumber] = useState(0);
  const [enteredSharesValue, setEnteredSharesValue] = useState(0);
  const [status, setStatus] = useState("");
  const [notes, setNotes] = useState("");
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentCampaign, setCurrentCampaign] = useState({});
  useEffect(() => {
    const fetchInvestments = async () => {
      setIsPageLoading(true);
      const investments = await getInvestmentsByCampaignId(campaignId, "PENDING");
      const campaign = await getCampaignById(campaignId);
      setInvestments(investments);  
      setCurrentCampaign(campaign);
      setIsPageLoading(false);
    };

    fetchInvestments();
  }, [campaignId]);
  const tableHeaders = [
    "الإسم",
    "رقم الجوال",
    "عدد الحصص",
    "قيمة الحصص",
    "الحالة",
    "ملاحظات",
    "إجراء",
  ];

  const setInvestmentForm = (dataObj) => {
    setInvestorName(dataObj.userFullName);
    setInvestorNumber(dataObj.userPhone);
    setRequestedSharesNumber(dataObj.requestedSharesNumber);
    setEnteredSharesValue(dataObj.requestedSharesNumber * dataObj.campaignSharePrice);
    setCampaignSharePrice(dataObj.campaignSharePrice);
    setNotes(dataObj.notes);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log(status)
      setIsLoading(true);
      await updateInvestment(selectedInvestment.id, {
        userFullName: investorName,
        requestedSharesNumber: Number(requestedSharesNumber),
        status: status,
        notes: notes,
      });
      if(status === "APPROVED"){
        await createNotification({
          title: currentCampaign.title,
          message: "تم قبول إستثمارك !",
          data: {type: "ACCEPT_INVESTMENT"},
          toUser: selectedInvestment.userId,
          isRead: false,
          createdAt: Timestamp.now(),
        });
      }else{
        await createNotification({
          title: currentCampaign.title,
          message: "لم تتم الموافقة على طلب إستثمارك، يمكنك التواصل مع الدعم لمزيد من التفاصيل",
          data: {type: "REJECT_INVESTMENT"},
          toUser: selectedInvestment.userId,
          isRead: false,
          createdAt: Timestamp.now(),
        });
      }
      navigate(0);
    } catch (e) {
      console.error("Error updating... ", e);
      notify("ERROR", "حصل خطأ.");
      setIsLoading(false);
    }
  };
  return isPageLoading ? (
    <PageLoader />
  ) : (
    <div>
      <ToastContainer />
      <Navbar />
      <PageTitle title={currentCampaign.title} />
      <p className="text-text-1-color font-bold text-base my-5">
        طلبات الإستثمار
      </p>
      <div className="overflow-x-auto">
        <table className="w-full text-sm">
          <thead className="text-xs text-white uppercase bg-primary-color font-bold text-center">
            <tr>
              {tableHeaders.map((header, index) => {
                return (
                  <th scope="col" className="px-6 py-3" key={index}>
                    {header}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="text-center">
            {investments.map((column, index) => {
              return (
                <tr
                  key={index}
                  className="bg-white text-text-1-color font-semibold odd:bg-white even:bg-ice-color"
                >
                  {
                    <>
                      <td className="px-6 py-4">{column.userFullName}</td>
                      <td className="px-6 py-4" dir="ltr">
                        {column.userPhone}
                      </td>
                      <td className="px-6 py-4">
                        {column.requestedSharesNumber}
                      </td>
                      <td className="px-6 py-4">
                        {(column.requestedSharesNumber * column.campaignSharePrice).toLocaleString()} ريال
                      </td>
                      <td className="px-6 py-4">{INV_STATUS[column.status]}</td>
                      <td className="px-6 py-4">{column.notes}</td>
                      <td className="px-6 py-4">
                        <CustomButton
                          title="إجراءات"
                          customStyle="px-8 py-2"
                          handleClick={() => {
                            setShowModal(true);
                            setInvestmentForm(column);
                            setSelectedInvestment(column);
                          }}
                        />
                      </td>
                    </>
                  }
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <CustomModal
        showModal={showModal}
        setShowModal={setShowModal}
        title="عرض الإستثمار"
        height="h-156"
        content={
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="mb-10">
              <div className="grid grid-cols-2 gap-10 mb-10">
                <div>
                  <label className="text-text-2-color font-bold">
                    المستثمر
                  </label>
                  <CustomInput
                    type="text"
                    value={investorName}
                    setValue={setInvestorName}
                    customStyle="mt-5"
                    isRequired={true}
                  />
                </div>
                <div>
                  <label className="text-text-2-color font-bold">
                    رقم الجوال
                  </label>
                  <CustomInput
                    type="text"
                    value={investorNumber}
                    setValue={setInvestorNumber}
                    customStyle="mt-5"
                    isDisabled={true}
                    isRequired={true}
                  />
                </div>
                <div>
                  <label className="text-text-2-color font-bold">
                    عدد الحصص
                  </label>
                  <CustomInput
                    type="number"
                    value={requestedSharesNumber}
                    setValue={setRequestedSharesNumber}
                    customStyle="mt-5"
                    isRequired={true}
                    handleCustomAction={(e) => {
                      setEnteredSharesValue(e.target.value * campaignSharePrice);
                    }}
                  />
                </div>
                <div>
                  <label className="text-text-2-color font-bold">
                    قيمة الحصص (ريال)
                  </label>
                  <CustomInput
                    type="text"
                    value={enteredSharesValue.toLocaleString()}
                    setValue={setEnteredSharesValue}
                    customStyle="mt-5"
                    isDisabled={true}
                    isRequired={true}
                  />
                </div>
              </div>
              <div>
                <label className="text-text-2-color font-bold">ملاحظات</label>
                <CustomTextArea
                  placeholder="هل يمكنني الدخول بالإستثمار؟"
                  value={notes}
                  setValue={setNotes}
                  customStyle="mt-5 h-96"
                  isRequired={true}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 gap-10">
              <CustomButton
                title="تم التحويل"
                type="success"
                customStyle="mt-5 p-5"
                isLoading={isLoading}
                handleClick={() => setStatus("APPROVED")}
              />
              <CustomButton
                title="رفض"
                type="danger"
                customStyle="mt-5 p-5"
                isLoading={isLoading}
                handleClick={() => setStatus("REJECTED")}
              />
            </div>
          </form>
        }
      />
    </div>
  );
}
