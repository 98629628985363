
export default function CustomModal({ showModal, setShowModal, title, content, height }) {
  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none text-start">
            <div className="relative w-auto mx-auto max-w-3xl">
              {/*content*/}
              <div className={`${height} border-0 rounded-3xl shadow-lg relative flex flex-col w-full bg-bg-color outline-none focus:outline-none p-10`}>
                {/*header*/}
                <div className="flex items-start justify-between">
                  <h6 className="text-lg font-bold text-text-1-color mb-10">{title}</h6>
                  <div className="rounded-full bg-secondary-color p-3 text-white" onClick={() => setShowModal(false)}>
                  <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"/>
                    </svg>
                  </div>
                </div>
                {/*body*/}
                <div className="overflow-auto p-2">
                  {content}
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
