import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../config/firebase";


const getLogsByCampaignId = async (campaignId) => {
    const preActiveLogsCollection = collection(firestore, "preActiveLogs");
    const q = query(preActiveLogsCollection, where("campaignId", "==", campaignId))

    const querySnapshot = await getDocs(q);
    const logs = querySnapshot.docs.map((data) => {
        var logObj = data.data();
        logObj['id'] = data.id;
        return logObj
    });

    return logs;
}

export default getLogsByCampaignId;