import React from "react";

export default function CustomInput({ type, placeholder, style, value, setValue, customStyle, isDisabled, isControlled, handleControlledInputChange, inputName, isRequired, handleCustomAction, hasSuggestions, suggestions, setSuggestions, setSelectedSuggestion }) {
  const handleInput = (e) => {
    // e.stopPropagation()
    if(!isControlled){
      setValue(e.target.value);
      if(typeof handleCustomAction === 'function'){
        handleCustomAction(e);
      }
    }else{
      handleControlledInputChange(e);
    }
    
  }
  return (
    <>
    <input
    disabled={isDisabled}
      type={type}
      name={inputName}
      value={value}
      style={style}
      onChange={handleInput}
      required={isRequired}
      className={`${customStyle} text-text-2-color disabled:bg-ice-color disabled:text-text-2-color w-full p-4 rounded-full outline
      outline-offset-1 outline-2 outline-input-border-color
      focus:outline-primary-color placeholder-text-3-color`}
      placeholder={placeholder}
      onWheel={(e) => e.target.blur()}
    />
    {hasSuggestions 
    ? suggestions.length > 0 ? <div className={`bg-white absolute top-56 border-1 border-slate-800 rounded-xl overflow-auto w-96 ${suggestions.length > 5 ? 'h-40' : `h-${suggestions.length * 8}`}`}>
      {suggestions.map((sugg, index) => (
        <p className="text-text-1-color px-5 py-3 hover:bg-secondary-color hover:text-white border-b-2 border-bg-color cursor-pointer" key={index} onClick={()=>{
          setValue(sugg.name);
          setSelectedSuggestion(sugg);
          setSuggestions([]);
        }}>{sugg.name} <b className="text-sm text-text-2-color">{sugg.phone}</b></p>
      ))}
    </div> : "" 
    : ""}
    </>
  );
}
