import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../config/firebase";

const getCampaignsInvestmentsAmount = async () => {
    const campaignsCollection = collection(firestore, "campaigns");
    const q = query(campaignsCollection, where("status", "!=", "DELETED"));
    const querySnapshot = await getDocs(q);

    var total = 0;

    querySnapshot.docs.map((data) => {
        var invAmount = data.data().investmentMaxAmount;
        total += invAmount;
    });
    
    return total;
}
export default getCampaignsInvestmentsAmount;