import { doc, getDoc } from "firebase/firestore";
import { firestore } from "../../config/firebase";


const getCampaignById = async (id) => {
    const docRef = doc(firestore, "campaigns", id);
    const docSnap = await getDoc(docRef);

    if(docSnap.exists()){
        return docSnap.data();
    }else{
        console.log("No such document.")
    }
}

export default getCampaignById;