import { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/PageTitle";
import CustomInput from "../../components/CustomInput";
import CustomTextArea from "../../components/CustomTextArea";
import CustomDropdown from "../../components/CustomDropdown";
import OPP_STATUS from "../../helpers/opportunity_status";
import { useParams } from "react-router-dom";
import getCampaignById from "../../lib/campaigns/getCampaignById";

export default function InvestmentOpportunityDetailsPage() {
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [investmentMaxAmount, setInvestmentMaxAmount] = useState(0);
  const [investmentROI, setInvestmentROI] = useState(0);
  const [status, setStatus] = useState("ACTIVE");
  const [shareNumber, setShareNumber] = useState(0);
  const [sharePrice, setSharePrice] = useState(0);
  const [projectDuration, setProjectDuration] = useState(0);
  const [avgOccupancyRate, setAvgOccupancyRate] = useState(0);
  const [expectedPaybackPeriodYears, setExpectedPaybackPeriodYears] =
    useState(0);
  const [unitsNumber, setUnitsNumber] = useState(0);
  const [location, setLocation] = useState("");
  const [locationUrl, setLocationUrl] = useState("");
  const [preActiveRemainingTime, setPreActiveRemainingTime] = useState("");
  const [leaseAgreementNumber, setLeaseAgreementNumber] = useState("");
  const [leaseAgreementDate, setLeaseAgreementDate] = useState("");
  const [description, setDescription] = useState("");
  const [terms, setTerms] = useState("");
  const [images, setImages] = useState([]);

  useEffect(() => {
    const fetchCampaign = async () => {
      const data = await getCampaignById(id);
      setTitle(data.title);
      setInvestmentMaxAmount(data.investmentMaxAmount);
      setInvestmentROI(data.investmentROI);
      setStatus(OPP_STATUS[data.status]);
      setShareNumber(data.shareNumber);
      setSharePrice(data.sharePrice);
      setProjectDuration(data.projectDuration);
      setAvgOccupancyRate(data.avgOccupancyRate ?? 0);
      setExpectedPaybackPeriodYears(data.expectedPaybackPeriodYears);
      setUnitsNumber(data.unitsNumber);
      setLocation(data.locationDescription);
      setLocationUrl(data.locationUrl);
      setPreActiveRemainingTime(data.preActiveRemainingTime);
      setLeaseAgreementNumber(data.leaseAgreementNumber ?? "");
      setLeaseAgreementDate(data.leaseAgreementDate ?? "");
      setDescription(data.description);
      setTerms(data.terms);
      setImages(data.images);
    }
    fetchCampaign();
  });
  

  return (
    <div>
      <Navbar />
      <div className="mb-10">
        <PageTitle title="تفاصيل الفرصة الإستثمارية" />
      </div>
      <form>
        <div className="grid grid-cols-2 gap-10 mb-10">
          <div>
            <label className="text-text-2-color font-bold">اسم الفرصة</label>
            <CustomInput
              type="text"
              value={title}
              setValue={setTitle}
              placeholder="ادخل اسم الفرصة..."
              customStyle="mt-5"
              isRequired={true}
              isDisabled={true}
            />
          </div>
          <div>
            <label className="text-text-2-color font-bold">
              المبلغ المطلوب للاستثمار (ريال)
            </label>
            <CustomInput
              type="number"
              value={investmentMaxAmount}
              setValue={setInvestmentMaxAmount}
              placeholder="ادخل المبلغ المطلوب للاستثمار..."
              customStyle="mt-5"
              isRequired={true}
              isDisabled={true}
            />
          </div>
          <div>
            <label className="text-text-2-color font-bold">
              العائد السنوي (%)
            </label>
            <CustomInput
              type="number"
              value={investmentROI}
              setValue={setInvestmentROI}
              placeholder="ادخل العائد السنوي (نسبة)..."
              customStyle="mt-5"
              isRequired={true}
              isDisabled={true}
            />
          </div>
          <div>
            <label className="text-text-2-color font-bold">مرحلة المشروع</label>
            <CustomDropdown
              options={OPP_STATUS}
              customStyle="mt-5 mb-5"
              defaultValue={status}
              setValue={setStatus}
              listName="status"
              isRequired={true}
              isDisabled={true}
            />
          </div>
          <div>
            <label className="text-text-2-color font-bold">عدد الحصص</label>
            <CustomInput
              type="number"
              value={shareNumber}
              setValue={setShareNumber}
              placeholder="ادخل عدد الحصص..."
              customStyle="mt-5"
              isRequired={true}
              isDisabled={true}
            />
          </div>
          <div>
            <label className="text-text-2-color font-bold">
              قيمة الحصة الواحدة (ريال)
            </label>
            <CustomInput
              type="number"
              value={sharePrice}
              setValue={setSharePrice}
              placeholder="ادخل قيمة الحصة الواحدة بالريال..."
              customStyle="mt-5"
              isRequired={true}
              isDisabled={true}
            />
          </div>
          <div>
            <label className="text-text-2-color font-bold">
              مدة المشروع (شهر)
            </label>
            <CustomInput
              type="number"
              value={projectDuration}
              setValue={setProjectDuration}
              placeholder="ادخل مدة المشروع بالأشهر..."
              customStyle="mt-5"
              isRequired={true}
              isDisabled={true}
            />
          </div>
          <div>
            <label className="text-text-2-color font-bold">
              متوسط معدل الإشغال (%)
            </label>
            <CustomInput
              type="number"
              value={avgOccupancyRate}
              setValue={setAvgOccupancyRate}
              placeholder="-"
              customStyle="mt-5"
              isRequired={true}
              isDisabled={true}
            />
          </div>
          <div>
            <label className="text-text-2-color font-bold">
              فترة استرداد رأس المال المتوقعة بالسنوات (سنة)
            </label>
            <CustomInput
              type="number"
              value={expectedPaybackPeriodYears}
              setValue={setExpectedPaybackPeriodYears}
              placeholder="ادخل فترة استرداد رأس المال المتوقعة بالسنوات..."
              customStyle="mt-5"
              isRequired={true}
              isDisabled={true}
            />
          </div>
          <div>
            <label className="text-text-2-color font-bold">عدد الوحدات</label>
            <CustomInput
              type="number"
              value={unitsNumber}
              setValue={setUnitsNumber}
              placeholder="ادخل عدد الوحدات..."
              customStyle="mt-5"
              isRequired={true}
              isDisabled={true}
            />
          </div>
          <div>
            <label className="text-text-2-color font-bold">
              الموقع للمشروع
            </label>
            <CustomInput
              type="text"
              value={location}
              setValue={setLocation}
              placeholder="مثال: الرياض-النرجس..."
              customStyle="mt-5"
              isRequired={true}
              isDisabled={true}
            />
          </div>
          <div>
            <label className="text-text-2-color font-bold">
              رابط موقع المشروع
            </label>
            <CustomInput
              type="text"
              value={locationUrl}
              setValue={setLocationUrl}
              placeholder="ادخل رابط موقع المشروع..."
              customStyle="mt-5"
              isRequired={true}
              isDisabled={true}
            />
          </div>
        </div>
        <div className="mb-10">
          <label className="text-text-2-color font-bold mb-10">
            الوقت المتبقي لإغلاق الحجز
          </label>
          <CustomInput
            type="text"
            value={preActiveRemainingTime}
            setValue={setPreActiveRemainingTime}
            placeholder="مثال: 3 أيام"
            customStyle="mt-5"
            isRequired={true}
            isDisabled={true}
          />
        </div>
        <div className="grid grid-cols-2 gap-10 mb-10">
        <div>
            <label className="text-text-2-color font-bold">
              رقم عقد الإيجار
            </label>
            <CustomInput
              type="text"
              value={leaseAgreementNumber}
              setValue={setLeaseAgreementNumber}
              placeholder="ادخل رقم عقد الإيجار..."
              customStyle="mt-5"
              isRequired={true}
              isDisabled={true}
            />
          </div>
          <div>
            <label className="text-text-2-color font-bold">
              تاريخ الإيجار
            </label>
            <CustomInput
              type="date"
              value={leaseAgreementDate}
              setValue={setLeaseAgreementDate}
              placeholder="ادخل تاريخ الإيجار بالميلادي..."
              customStyle="mt-5 text-right"
              isRequired={true}
              isDisabled={true}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 mb-10">
          <div>
            <label className="text-text-2-color font-bold mb-10">
              وصف عن الفرصة الإستثمارية
            </label>
            <CustomTextArea
              placeholder="ادخل وصف عن الفرصة الإستثمارية..."
              value={description}
              setValue={setDescription}
              customStyle="mt-5 h-96"
              isRequired={true}
              isDisabled={true}
            />
          </div>
          <div>
            <label className="text-text-2-color font-bold">
              شروط وأحكام الفرصة الإستثمارية
            </label>
            <CustomTextArea
              placeholder="ادخل شروط وأحكام الفرصة الإستثمارية..."
              value={terms}
              setValue={setTerms}
              customStyle="mt-5 h-96"
              isRequired={true}
              isDisabled={true}
            />
          </div>
        </div>

        <label className="text-text-2-color font-bold">
          صور عن الفرصة الإستثمارية
        </label>
        <div className="grid grid-cols-2 gap-10 mt-5 mb-16">
          {
            images.map((data, index) => (
              <div key={index}>
                <img src={data} alt="img" className="w-full h-full" />
              </div>
            ))
          }
        </div>
      </form>
    </div>
  );
}
