import { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/PageTitle";
import CustomButton from "../../components/CustomButton";
import CustomModal from "../../components/CustomModal";
import { useNavigate } from "react-router-dom";
import getCampaigns from "../../lib/campaigns/getCampaigns";
import OPP_STATUS from "../../helpers/opportunity_status";
import updateCampaign from "../../lib/campaigns/updateCampaign";
import getInvestmentsCountByCampaignId from "../../lib/investments/getInvestmentsCountByCampaignId";
import { Timestamp } from "firebase/firestore";
import { ToastContainer } from "react-toastify";
import notify from "../../utils/notify";
import PageLoader from "../../components/PageLoader";
import createNotification from "../../lib/notifications/createNotification";
import getInvestmentsByCampaignId from "../../lib/investments/getInvestmentsByCampaignId";

export default function OpportunitiesManagementPage() {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState({});
  const [selectedInvestments, setSelectedInvestments] = useState([]);
  const [pendingInvestments, setPendingInvestments] = useState([]);
  const [totalPendingInvestments, setTotalPendingInvestments] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  useEffect(() => {
    const fetchCampaigns = async () => {
      const fetchedCampaigns = await getCampaigns();
      var total = 0;
      const tempInvestments = await Promise.all(
        fetchedCampaigns.map(async (campaign) => {
          const invCount = await getInvestmentsCountByCampaignId(
            campaign.id,
            "PENDING"
          );
          // Check if the investment count is not 0
          if (invCount !== 0) {
            total = total + invCount;
            return {
              campaignId: campaign.id,
              title: campaign.title,
              count: invCount,
            };
          }
        })
      );
      // Remove undefined values from the array.
      const filteredInvestments = tempInvestments.filter(
        (investment) => investment !== undefined
      );
      // Set the values
      setCampaigns(fetchedCampaigns);
      setPendingInvestments(filteredInvestments);
      setTotalPendingInvestments(total);
      setIsPageLoading(false);
    };
    fetchCampaigns();
  }, []);
  const pendingInvTableHeaders = [
    "اسم الفرصة",
    `عدد الطلبات المعلقة (${totalPendingInvestments})`,
    "إجراء",
  ];
  const campaignsTableHeaders = [
    "اسم الفرصة",
    "الموقع",
    "مبلغ الإستثمار",
    "العائد السنوي",
    "مرحلة المشروع",
    "وقت الإنشاء",
    "وقت الإغلاق",
    "الحالة",
    "إجراءات",
  ];

  const handleDeleteInvestment = async () => {
    setIsLoading(true);
    const investmentsInCampaign = await getInvestmentsCountByCampaignId(
      selectedCampaign.id,
      "APPROVED"
    );
    if (investmentsInCampaign <= 0) {
      await updateCampaign(selectedCampaign.id, {
        status: selectedCampaign.status === "DELETED" ? "ACTIVE" : "DELETED",
      });
      navigate(0);
    } else {
      notify(
        "ERROR",
        "يوجد سجل استثمارات في هذه الحملة, يرجى حذف جميع السجلات لحذف الحملة."
      );
      setIsLoading(false);
    }
  };

  const handleCompleteInvestment = async () => {
    setIsLoading(true);
    try{
      await updateCampaign(selectedCampaign.id, {
        status: selectedCampaign.status === "ACTIVE" ? "COMPLETED" : "ACTIVE",
        closedAt: Timestamp.now(),
      });
      if (selectedCampaign.status === "ACTIVE" && selectedInvestments > 0) {
        selectedInvestments.map(async (investment, index) => {
          await createNotification({
            title: selectedCampaign.title,
            message: "تم تغطية الحملة الإستثمارية بالكامل !",
            data: { type: "COMPLETE_CAMPAIGN" },
            toUser: investment.userId,
            isRead: false,
            createdAt: Timestamp.now(),
          });
          if (selectedInvestments.length === index + 1) {
            navigate(0);
          }
        });
      } else {
        navigate(0);
      }
    }catch(e){
      console.log(e);
    }
  };

  const handleReservationStatus = async () => {
    setIsLoading(true);
    try {
      await updateCampaign(selectedCampaign.id, {
        status: "PREACTIVE",
      });
    } catch (e) {
      notify("ERROR", "حصل خطأ، الرجاء المحاولة لاحقاً.");
    }
    navigate(0);
  };

  return isPageLoading ? (
    <PageLoader />
  ) : (
    <div>
      <ToastContainer />
      <Navbar />
      {/* Pending investments */}
      <div className="mb-10">
        <PageTitle title="طلبات الإستثمار الجديدة (المعلقة)" />
      </div>
      <div className="overflow-x-auto mb-32">
        <table className="w-full text-sm">
          <thead className="text-xs text-white uppercase bg-primary-color font-bold text-center">
            <tr>
              {pendingInvTableHeaders.map((header, index) => {
                return (
                  <th scope="col" className="px-6 py-3" key={index}>
                    {header}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="text-center">
            {pendingInvestments.map((column, index) => {
              return (
                <tr
                  key={index}
                  className="bg-white text-text-1-color font-semibold odd:bg-white even:bg-ice-color"
                >
                  {
                    <>
                      <td className="px-6 py-4">{column.title}</td>
                      <td className="px-6 py-4">{column.count}</td>
                      <td className="px-6 py-4">
                        <CustomButton
                          title="عرض"
                          customStyle="px-8 py-2"
                          handleClick={async () => {
                            navigate(
                              `/investments_requests/${column.campaignId}`
                            );
                          }}
                        />
                      </td>
                    </>
                  }
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* Opportunities Management */}
      <div className="flex flex-row justify-between items-center mb-10">
        <PageTitle title="إدارة الفرص الإستثمارية" />
        <div className="w-60">
          <CustomButton
            title="إضافة فرصة جديدة"
            customStyle="p-2"
            handleClick={() => navigate("/create_investment_opportunity")}
          />
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="w-full text-sm">
          <thead className="text-xs text-white uppercase bg-primary-color font-bold text-center">
            <tr>
              {campaignsTableHeaders.map((header, index) => {
                return (
                  <th scope="col" className="px-6 py-3" key={index}>
                    {header}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="text-center">
            {campaigns.map((column, index) => {
              return (
                <tr
                  key={index}
                  className="bg-white text-text-1-color font-semibold odd:bg-white even:bg-ice-color"
                >
                  {
                    <>
                      <td className="px-6 py-4">{column.title}</td>
                      <td className="px-6 py-4">
                        {column.locationDescription}
                      </td>
                      <td className="px-6 py-4">
                        {column.investmentMaxAmount.toLocaleString()} ريال
                      </td>
                      <td className="px-6 py-4">{column.investmentROI}%</td>
                      <td className="px-6 py-4">{column.projectStatus}</td>
                      <td className="px-6 py-4">
                        {column.createdAt.toDate().toISOString().split("T")[0]}
                      </td>
                      <td className="px-6 py-4">
                        {column.closedAt !== undefined
                          ? column.status === "COMPLETED" 
                          ? column.closedAt.toDate().toISOString().split("T")[0]
                          : " - " : " - "}
                      </td>
                      <td className="px-6 py-4">{OPP_STATUS[column.status]}</td>
                      <td className="px-6 py-4">
                        <CustomButton
                          title="إجراءات"
                          customStyle="px-8 py-2"
                          handleClick={async () => {
                            setSelectedCampaign(column);
                            const fetchedInvestments =
                              await getInvestmentsByCampaignId(
                                column.id,
                                "APPROVED"
                              );
                            setSelectedInvestments(fetchedInvestments);
                            setShowModal(true);
                          }}
                        />
                      </td>
                    </>
                  }
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <CustomModal
        showModal={showModal}
        setShowModal={setShowModal}
        title="إجراءات"
        content={
          <div>
            <div className="py-3">
              <CustomButton
                title="عرض تفاصيل الفرصة"
                customStyle="py-4 px-32"
                handleClick={() =>
                  navigate(
                    `/investment_opportunity_details/${selectedCampaign.id}`
                  )
                }
              />
            </div>
            <div className="py-3">
              <CustomButton
                title="تعديل بيانات الفرصة"
                customStyle="py-4 px-32"
                handleClick={() =>
                  navigate(
                    `/edit_investment_opportunity/${selectedCampaign.id}`
                  )
                }
              />
            </div>
            <div className="py-3">
              <CustomButton
                title="عرض طلبات الإستثمار"
                customStyle="py-4 px-32"
                handleClick={() =>
                  navigate(`/investments_requests/${selectedCampaign.id}`)
                }
              />
            </div>
            <div className="py-3">
              <CustomButton
                title="إدارة سجل الإستثمارات"
                customStyle="py-4 px-32"
                handleClick={() =>
                  navigate(
                    `/opportunity_investment_records/${selectedCampaign.id}`
                  )
                }
              />
            </div>
            {selectedCampaign.status !== "COMPLETED" ? (
              <div className="py-3">
                <CustomButton
                  title={
                    selectedCampaign.status === "DELETED"
                      ? "إرجاع الفرصة"
                      : "حذف الفرصة"
                  }
                  customStyle="py-4 px-32"
                  isLoading={isLoading}
                  handleClick={handleDeleteInvestment}
                />
              </div>
            ) : (
              ""
            )}
            {selectedCampaign.status !== "DELETED" ? (
              <div className="py-3">
                <CustomButton
                  title={
                    selectedCampaign.status === "ACTIVE"
                      ? "إخفاء زر الاستثمار: مكتملة"
                      : "إعادة زر الاستثمار: نشط"
                  }
                  customStyle="py-4 px-32"
                  isLoading={isLoading}
                  handleClick={handleCompleteInvestment}
                />
              </div>
            ) : (
              ""
            )}
            {selectedCampaign.status !== "PREACTIVE" ? (
              <div className="py-3">
                <CustomButton
                  title="تغيير الحالة إلى : متاح للحجز"
                  customStyle="py-4 px-32"
                  isLoading={isLoading}
                  handleClick={handleReservationStatus}
                />
              </div>
            ) : (
              ""
            )}
            <div className="py-3">
              <CustomButton
                title="سجل التقارير"
                customStyle="py-4 px-32"
                handleClick={() =>
                  navigate(
                    `/opportunity_investment_reports/${selectedCampaign.id}`
                  )
                }
              />
            </div>
            <div className="py-3">
              <CustomButton
                title="سجل الحجوزات"
                customStyle="py-4 px-32"
                handleClick={() =>
                  navigate(`/reservation_records/${selectedCampaign.id}`)
                }
              />
            </div>
          </div>
        }
      />
    </div>
  );
}
