import { useState } from "react";
import CustomInput from "../../../components/CustomInput";
import { REGIONS } from "../../../helpers/regions";
import { NATIONALITIES } from "../../../helpers/nationalities";
import { INCOME } from "../../../helpers/income";
import ViewBankAccountForm from "./ViewBankAccountForm";

export default function ViewUserInfoForm({ userInfo }) {
  const [fullName, setFullName] = useState(userInfo.name);
  const [phoneNumber, setPhoneNumber] = useState(userInfo.phone.substring(4, userInfo.phone.length));
  const [countryCode, setCountryCode] = useState(userInfo.phone.substring(0, 4));
  const [city, setCity] = useState(REGIONS['SA'][userInfo.city]);
  const [nationality, setNationality] = useState(NATIONALITIES[userInfo.nationality]);
  const [monthlyIncome, setMonthlyIncome] = useState(INCOME[userInfo.income]);
  const [nationalId, setNationalId] = useState(userInfo.nationalId);
  const [email, setEmail] = useState(userInfo.emailAddress);
  return (
    <div>
      <div>
        <label className="text-text-2-color font-bold">الإسم كاملا</label>
        <CustomInput
          type="text"
          value={fullName}
          setValue={setFullName}
          isDisabled={true}
          placeholder="ادخل الإسم كاملاً"
          customStyle="mt-5 mb-5"
        />
      </div>
      <div>
        <label className="text-text-2-color font-bold">رقم الجوال</label>
        <div className="flex gap-5">
          <CustomInput
            type="text"
            value={countryCode}
            setValue={setCountryCode}
            isDisabled={true}
            placeholder="+966"
            style={{ maxWidth: 100 }}
            customStyle="mt-5 mb-5"
          />
          <CustomInput
            type="text"
            value={phoneNumber}
            setValue={setPhoneNumber}
            isDisabled={true}
            placeholder="5XXXXXXXX"
            customStyle="mt-5 mb-5"
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-10">
        <div>
          <label className="text-text-2-color font-bold">المدينة</label>
          <CustomInput
            type="text"
            value={city}
            isDisabled={true}
            setValue={setCity}
            placeholder="اختر المدينة"
            customStyle="mt-5 mb-5"
          />
        </div>
        <div>
          <label className="text-text-2-color font-bold">الجنسية</label>
          <CustomInput
            type="text"
            value={nationality}
            setValue={setNationality}
            isDisabled={true}
            placeholder="اختر الجنسية"
            customStyle="mt-5 mb-5"
          />
        </div>
      </div>
      <div>
        <label className="text-text-2-color font-bold">الدخل الشهري</label>
        <CustomInput
          type="text"
          value={monthlyIncome}
          setValue={setMonthlyIncome}
          isDisabled={true}
          placeholder="اختر الدخل الشهري"
          customStyle="mt-5 mb-5"
        />
      </div>
      <div>
        <label className="text-text-2-color font-bold">الهوية الوطنية / الإقامة</label>
        <CustomInput
          type="text"
          value={nationalId}
          setValue={setNationalId}
          isDisabled={true}
          placeholder="-"
          customStyle="mt-5 mb-5"
        />
      </div>
      <div>
        <label className="text-text-2-color font-bold">البريد الإلكتروني</label>
        <CustomInput
          type="text"
          value={email}
          setValue={setEmail}
          isDisabled={true}
          placeholder="-"
          customStyle="mt-5 mb-5"
        />
      </div>
      {
        userInfo.bankAccounts.length > 0 
        ? <ViewBankAccountForm bankAccounts={userInfo.bankAccounts} /> 
        : <p className="text-text-1-color">لا توجد حسابات بنكية</p>
      }
    </div>
  );
}
