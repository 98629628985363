export const BANKS_NAMES = {
    ALINMA: 'الإنماء',
    ALRAJHI: 'الراجحي',
    NCB: 'الأهلي التجاري',
    SAMBA: 'سامبا',
    RIYAD: 'الرياض',
    SAIB: 'السعودي للإستثمار',
    SABB: 'ساب',
    ARAB_NATIONAL: 'العربي الوطني',
    ALAWWAL: 'الأول',
    ALJAZIRA: 'الجزيرة',
    ALBILAD: 'البلاد',
    SAUDI_FRANSI: 'السعودي الفرنسي',
    GULF_INTERNATIONAL: 'الخليج الدولي',
    ALKHAIR: 'الخير',
  };
  