

const ImagePreview = ({ images, setImages }) => {
    

    const onImageRemove = (index) => {
        const updatedImageFiles = images.filter((_, i) => i !== index);
        setImages(updatedImageFiles);
      };

    return (
        images.map((data, index) => (
            <div className="flex flex-col items-center justify-center w-full h-128 rounded-3xl cursor-pointer bg-box-color p-5" key={index}>
              <img src={data} alt="img" className="w-full h-full" onClick={() => onImageRemove(index)} />
            </div>
          ))
    )
}

export default ImagePreview;