import { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/PageTitle";
import CustomButton from "../../components/CustomButton";
import CustomModal from "../../components/CustomModal";
import CustomInput from "../../components/CustomInput";
import getInvestmentsByCampaignId from "../../lib/investments/getInvestmentsByCampaignId";
import { useNavigate, useParams } from "react-router-dom";
import updateInvestment from "../../lib/investments/updateInvestment";
import getCampaignById from "../../lib/campaigns/getCampaignById";
import getUserByName from "../../lib/users/getUserByName";
import createInvestment from "../../lib/investments/createInvestment";
import { Timestamp } from "firebase/firestore";
import PageLoader from "../../components/PageLoader";
import notify from "../../utils/notify";
import { ToastContainer } from "react-toastify";

export default function OpportunityInvestmentRecordsPage() {
  const { campaignId } = useParams();
  const navigate = useNavigate();
  const [selectedInvestment, setSelectedInvestment] = useState({});
  const [currentCampaign, setCurrentCampaign] = useState({});
  const [selectedSuggestedUser, setSelectedSuggestedUser] = useState({});
  
  const [investorName, setInvestorName] = useState("");
  const [requestedSharesNumber, setRequestedSharesNumber] = useState(0);
  const [sharesPrice, setSharesPrice] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [investments, setInvestments] = useState([]);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  useEffect(() => {
    const fetchInvestments = async () => {
      setIsPageLoading(true);
      const investments = await getInvestmentsByCampaignId(campaignId, "APPROVED");
      setInvestments(investments);
      setIsPageLoading(false);
    };

    const fetchCampaign = async () => {
      setIsPageLoading(true);
      const campaign = await getCampaignById(campaignId);
      setCurrentCampaign(campaign);
      setIsPageLoading(false);
    };

    fetchInvestments();
    fetchCampaign();
  }, [campaignId]);
  const tableHeaders = [
    "الاسم",
    "رقم الجوال",
    "قيمة الإستثمار",
    "عدد الحصص",
    "قيمة الحصة الواحدة",
    "تاريخ الإستثمار",
    "إجراء",
  ];

  const handleDelete = async () => {
    setIsLoading(true);
    try{
      await updateInvestment(selectedInvestment.id, { status: "DELETED" });
      navigate(0);
    }catch(e){
      setIsLoading(false);
      notify("ERROR", "حصل خطأ أثناء إجراء العملية.");
    }
    
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if(Object.keys(selectedSuggestedUser).length === 0){
      notify("WARNING", "الرجاء إختيار مستثمر صحيح.");
      setIsLoading(false);
      return;
    }
    console.log("Campaign: ", currentCampaign);
    console.log("User: ", selectedSuggestedUser)
    try{
      await createInvestment({
        avegMonthlyShareDistribution: Number(currentCampaign.avegMonthlyShareDistribution),
        campaignId: campaignId,
        campaignLocationDescription: currentCampaign.locationDescription,
        campaignROI: Number(currentCampaign.investmentROI),
        campaignShareNumber: Number(currentCampaign.shareNumber),
        campaignSharePrice: Number(currentCampaign.sharePrice),
        campaignTitle: currentCampaign.title,
        notes: "لا يوجد",
        projectDuration: Number(currentCampaign.projectDuration),
        requestedSharesNumber: Number(requestedSharesNumber),
        status: "APPROVED",
        userBankAccounts: selectedSuggestedUser.bankAccounts,
        userFullName: selectedSuggestedUser.name,
        userId: selectedSuggestedUser.id,
        userPhone: selectedSuggestedUser.phone,
        createdAt: Timestamp.now()
      });
      navigate(0);
    }catch(e){
      console.error("Cannot create investment: ", e);
      notify("ERROR", "حصل خطأ أثناء إنشاء السجل.");
      setIsLoading(false);
    }
  }

  return isPageLoading ? (
    <PageLoader />
  ) : (
    <div>
      <ToastContainer />
      <Navbar />
      <PageTitle title={currentCampaign.title}/>
      <div className="flex flex-row justify-between items-center mb-16">
        <p className="text-text-1-color font-bold text-base my-5">
          إدارة سجل الإستثمارات
        </p>
        <div className="w-60">
          <CustomButton
            title="إضافة سجل"
            customStyle="p-3"
            handleClick={() => setShowModal(true)}
          />
        </div>
      </div>
      {
        investments.length === 0 
        ? <p className="text-center text-3xl text-danger-color">لا يوجد سجل استثمارات</p>
        : <div className="overflow-x-auto">
        <table className="w-full text-sm">
          <thead className="text-xs text-white uppercase bg-primary-color font-bold text-center">
            <tr>
              {tableHeaders.map((header, index) => {
                return (
                  <th scope="col" className="px-6 py-3" key={index}>
                    {header}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="text-center">
            {investments.map((column, index) => {
              return (
                <tr
                  key={index}
                  className="bg-white text-text-1-color font-semibold odd:bg-white even:bg-ice-color"
                >
                  {
                    <>
                      <td className="px-6 py-4">{column.userFullName}</td>
                      <td className="px-6 py-4" dir="ltr">
                        {column.userPhone}
                      </td>
                      <td className="px-6 py-4">
                        {(
                          column.requestedSharesNumber *
                          column.campaignSharePrice
                        ).toLocaleString()}{" "}
                        ريال
                      </td>
                      <td className="px-6 py-4">
                        {column.requestedSharesNumber}
                      </td>
                      <td className="px-6 py-4">
                        {column.campaignSharePrice.toLocaleString()} ريال
                      </td>
                      <td className="px-6 py-4">
                        {column.createdAt.toDate().toISOString().split("T")[0]}
                      </td>
                      <td className="px-6 py-4">
                        <CustomButton
                          title="حذف"
                          type="danger"
                          customStyle="px-8 py-2"
                          handleClick={() => {
                            setShowConfirmationModal(true);
                            setSelectedInvestment(column);
                          }}
                        />
                      </td>
                    </>
                  }
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      }
      <CustomModal
        showModal={showModal}
        setShowModal={setShowModal}
        title="إضافة سجل استثمار"
        content={
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="w-96">
              <div>
                <label className="text-text-2-color font-bold">المستثمر</label>
                <CustomInput
                  type="text"
                  value={investorName}
                  setValue={setInvestorName}
                  placeholder="اكتب اسم المستثمر"
                  customStyle={`mt-5 mb-5 ${
                    Object.keys(selectedSuggestedUser).length !== 0
                      ? "bg-success-color text-white"
                      : ""
                  }`}
                  hasSuggestions={true}
                  suggestions={suggestions}
                  setSuggestions={setSuggestions}
                  setSelectedSuggestion={setSelectedSuggestedUser}
                  isRequired={true}
                  handleCustomAction={async (e) => {
                    const value = e.target.value.trim("");
                    if (value.length === 0) {
                      setSuggestions([]);
                      setSelectedSuggestedUser({});
                    } else {
                      const arr = [];
                      const users = await getUserByName(value);
                      users.map((user) => arr.push(user));
                      const returnedArray = arr.filter((u) => u.name === value);
                      if (returnedArray.length === 0) {
                        setSelectedSuggestedUser({});
                      }
                      setSuggestions(arr);
                    }
                    console.log("Selected: ", selectedSuggestedUser);
                  }}
                />
              </div>
              <div>
                <label className="text-text-2-color font-bold">عدد الحصص</label>
                <CustomInput
                  type="number"
                  value={requestedSharesNumber}
                  setValue={setRequestedSharesNumber}
                  placeholder="ادخل مبلغ الإستثمار"
                  customStyle="mt-5 mb-5"
                  handleCustomAction={(e) => {
                    setSharesPrice(currentCampaign.sharePrice * e.target.value);
                  }}
                  isRequired={true}
                />
              </div>
              <div>
                <label className="text-text-2-color font-bold">
                  قيمة الحصص
                </label>
                <CustomInput
                  type="text"
                  value={sharesPrice.toLocaleString()}
                  setValue={setSharesPrice}
                  placeholder="ادخل قيمة الحصص"
                  isDisabled={true}
                  customStyle="mt-5 mb-10"
                  isRequired={true}
                />
              </div>
              <CustomButton title="إضافة" customStyle="py-5" />
            </div>
          </form>
        }
      />

      <CustomModal
        showModal={showConfirmationModal}
        setShowModal={setShowConfirmationModal}
        title="حذف سجل الإستثمار"
        content={
          <div className="w-96 text-center">
            <p className="text-text-2-color mb-10 text-lg">
              هل أنت متأكد من حذف هذا السجل؟
            </p>
            <div className="grid grid-cols-2 gap-10">
              <CustomButton
                title="حذف"
                customStyle="py-5"
                type="danger"
                isLoading={isLoading}
                handleClick={handleDelete}
              />
              <CustomButton
                title="رجوع"
                customStyle="py-5"
                type="outline"
                isLoading={isLoading}
                handleClick={() => setShowConfirmationModal(false)}
              />
            </div>
          </div>
        }
      />
    </div>
  );
}
