export const INCOME = {
    LESS_5000: '5000 ريال او اقل',
    BETWEEN_6000_10000: '6,000 ريال - 10,000 ريال',
    BETWEEN_11000_15000: '11,000 ريال - 15,000 ريال',
    BETWEEN_16000_20000: '16,000 ريال - 20,000 ريال',
    BETWEEN_21000_25000: '21,000 ريال - 25,000 ريال',
    BETWEEN_26000_30000: '26,000 ريال - 30,000 ريال',
    BETWEEN_31000_40000: '31,000 ريال - 40,000 ريال',
    BETWEEN_40000_50000: '41,000 ريال - 50,000 ريال',
    BETWEEN_51000_60000: '51,000 ريال - 60,000 ريال',
    BETWEEN_61000_70000: '61,000 ريال - 70,000 ريال',
    BETWEEN_71000_80000: '71,000 ريال - 80,000 ريال',
    BETWEEN_81000_90000: '81,000 ريال - 90,000 ريال',
    BETWEEN_91000_1000000: '91,000 ريال - 100,000 ريال',
    BETWEEN_101000_200000: '101,000 ريال - 200,000 ريال',
    BETWEEN_201000_300000: '201,000 ريال - 300,000 ريال',
    BETWEEN_301000_400000: '301,000 ريال - 400,000 ريال',
    BETWEEN_401000_500000: '401000 ريال - 500,000 ريال',
    BETWEEN_501000_600000: '501,000 ريال - 600,000 ريال',
    BETWEEN_601000_700000: '601,000 ريال - 700,000 ريال',
    BETWEEN_701000_800000: '701,000 ريال - 800,000 ريال',
    BETWEEN_801000_900000: '801,000 ريال - 900,000 ريال',
    BETWEEN_901000_1000000: '901,000 ريال - 1,000,000 ريال',
    MORE_1000000: '1,000,000 ريال او اكثر',
  };