import CustomButton from '../../../components/CustomButton';
import CustomDropdown from '../../../components/CustomDropdown';
import CustomInput from '../../../components/CustomInput'
import { BANKS_NAMES } from '../../../helpers/banks';

export default function BankAccountForm({ input, index, inputFields, setInputFields }) {
  return (
    <div className="outline outline-1 outline-text-3-color rounded-3xl p-10 mt-10" key={index}>
      <div className="grid grid-cols-2 justify-between">
        <p className="text-1-color font-bold mb-10">حساب بنكي #{index+1}</p>
        <CustomButton title="حذف" customStyle="h-10" type="danger" handleClick={() => {
          let data = [...inputFields];
          data.splice(index, 1)
          setInputFields(data)
        }} />
      </div>

      <div>
        <label className="text-text-2-color font-bold">الاسم</label>
        <CustomInput
          type="text"
          placeholder="ادخل اسم المستفيد..."
          customStyle="mt-5 mb-5"
          inputName="beneficiary"
          value={input.beneficiary}
          isControlled={true}
          isRequired={true}
          handleControlledInputChange={(e) => {
            let data = [...inputFields];
            data[index][e.target.name] = e.target.value;
            setInputFields(data);
          }}
        />
      </div>
      <div>
        <label className="text-text-2-color font-bold">البنك</label>
        <CustomDropdown
            options={BANKS_NAMES}
            customStyle="mt-5 mb-5"
            listName="banksNames"
            inputName="bank"
            value={input.bank}
            isControlled={true}
            isRequired={true}
            defaultValue={inputFields[index].bank}
            handleControlledInputChange={(e) => {
              let data = [...inputFields];
              data[index][e.target.name] = e.target.value;
              setInputFields(data);
            }}
          />
      </div>
      <div>
        <label className="text-text-2-color font-bold">رقم الايبان</label>
        <CustomInput
          type="text"
          placeholder="ادخل رقم الايبان"
          customStyle="mt-5 mb-5"
          inputName="IBAN"
          value={input.IBAN}
          isControlled={true}
          isRequired={true}
          handleControlledInputChange={(e) => {
            let data = [...inputFields];
            data[index][e.target.name] = e.target.value;
            setInputFields(data);
          }}
        />
      </div>
    </div>
  )
}