import { useState } from "react";
import CustomInput from "../../../components/CustomInput";
import CustomButton from "../../../components/CustomButton";
import createUser from "../../../lib/users/createUser";
import CustomDropdown from "../../../components/CustomDropdown";
import { COUNTRY_KEYS } from "../../../helpers/country_keys";
import { NATIONALITIES } from "../../../helpers/nationalities";
import { REGIONS } from "../../../helpers/regions";
import { INCOME } from "../../../helpers/income";
import AddBankAccountForm from "./BankAccountForm";
import generateId from "../../../utils/generateId";
import { useNavigate } from "react-router-dom";
import { isUserInfoValid } from "../../../utils/inputValidations";
import { isPhoneNumberExist } from "../../../utils/dataValidations";
import { ToastContainer } from "react-toastify";

export default function AddUserForm() {
  const navigate = useNavigate();
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryCode, setCountryCode] = useState("+966");
  const [region, setRegion] = useState("Riyadh");
  const [nationality, setNationality] = useState("Saudi");
  const [income, setIncome] = useState("LESS_5000");
  const [nationalId, setNationalId] = useState("");
  const [email, setEmail] = useState("");
  const [bankInputFields, setBankInputFields] = useState([
    { id: generateId(), beneficiary: "", bank: "ALINMA", IBAN: "" },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
      try{
        if(!isUserInfoValid(fullName, phoneNumber)){
          setIsLoading(false);
          return;
        }else if(await isPhoneNumberExist(`${countryCode}${phoneNumber}`, "")){
          setIsLoading(false);
          return;
        }else{
          await createUser(
            fullName,
            `${countryCode}${phoneNumber}`,
            region,
            nationality,
            income,
            nationalId,
            email,
            bankInputFields
          );
          console.log("done!")
          navigate(0);
        }
        
      }catch(e){
        setIsLoading(false);
        console.log(e);
      }
      setIsLoading(false);
  }
  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <ToastContainer />
      <div>
        <label className="text-text-2-color font-bold">الإسم كاملا</label>
        <CustomInput
          type="text"
          value={fullName}
          setValue={setFullName}
          placeholder="ادخل الإسم كاملاً"
          customStyle="mt-5 mb-5"
          isRequired={true}
        />
      </div>
      <div>
        <label className="text-text-2-color font-bold">رقم الجوال</label>
        <div className="flex gap-5">
          <CustomDropdown
            options={COUNTRY_KEYS}
            customStyle="mt-5 mb-5"
            style={{ maxWidth: 100 }}
            setValue={setCountryCode}
            listName="countryCodes"
            isRequired={true}
          />
          <CustomInput
            type="number"
            value={phoneNumber}
            setValue={setPhoneNumber}
            placeholder="5XXXXXXXX"
            customStyle="mt-5 mb-5 w-full"
            isRequired={true}
          />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-10">
        <div>
          <label className="text-text-2-color font-bold">المدينة</label>
          <CustomDropdown
            options={REGIONS}
            customStyle="mt-5 mb-5"
            setValue={setRegion}
            listName="regions"
            isRequired={true}
          />
        </div>
        <div>
          <label className="text-text-2-color font-bold">الجنسية</label>
          <CustomDropdown
            options={NATIONALITIES}
            customStyle="mt-5 mb-5"
            setValue={setNationality}
            listName="nationalities"
            isRequired={true}
          />
        </div>
      </div>
      <div>
        <label className="text-text-2-color font-bold">الدخل الشهري</label>
        <CustomDropdown
          options={INCOME}
          customStyle="mt-5 mb-5"
          setValue={setIncome}
          listName="income"
          isRequired={true}
        />
      </div>
      <div>
        <label className="text-text-2-color font-bold">الهوية الوطنية / الإقامة</label>
        <CustomInput
            type="number"
            value={nationalId}
            setValue={setNationalId}
            placeholder="ادخل رقم الهوية أو الإقامة..."
            customStyle="mt-5 mb-5 w-full"
            isRequired={false}
          />
      </div>
      <div>
        <label className="text-text-2-color font-bold">البريد الإلكتروني</label>
        <CustomInput
            type="email"
            value={email}
            setValue={setEmail}
            placeholder="ادخل البريد الإلكتروني..."
            customStyle="mt-5 mb-5 w-full"
            isRequired={false}
          />
      </div>
      {bankInputFields.map((input, index) => {
        return (
          <AddBankAccountForm
            input={input}
            index={index}
            inputFields={bankInputFields}
            setInputFields={setBankInputFields}
          />
        );
      })}
      <div className="grid grid-cols-3">
        <CustomButton
          type="outline"
          title="إضافة حساب بنكي جديد"
          customStyle="p-4 mt-5 mb-24 w-48"
          handleClick={() => {
            if (bankInputFields.length < 5) {
              let newFields = { id: generateId(), beneficiary: "", bank: "ALINMA", IBAN: "" };
              setBankInputFields([...bankInputFields, newFields]);
            }
          }}
        />
      </div>
      {
        <CustomButton
        title="إضافة"
        customStyle="p-5"
        isLoading={isLoading}
      />
      }
      
    </form>
  );
}
