import { useEffect, useState } from "react";
import CustomInput from "../../components/CustomInput";
import CustomButton from "../../components/CustomButton";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import notify from "../../utils/notify";
import { ToastContainer } from "react-toastify";

export default function LoginPage() {
  // Define input values
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { login, currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if(currentUser){
      navigate("/");
    }
  }, [currentUser, navigate])

  const handleSubmit = async (e) => {
    e.preventDefault();
    try{
      setIsLoading(true);
      await login(email, password);
      navigate("/")
    }catch(e){
      setIsLoading(false);
      notify("ERROR", "الرجاء التأكد من البيانات المدخلة.");
      console.error("Error logging in: ", e);
    }

    setIsLoading(false);
  }
  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <ToastContainer />
      <div className="container m-auto">
      <img
        src="logo.png"
        className="m-auto mt-20 mb-20"
        alt="logo"
        height={200}
        width={200}
      />
      <div className="bg-box-color py-16 px-10 rounded-3xl desktop:w-1/2 tablet:w-4/5 m-auto">
        <label className="text-text-2-color font-bold">البريد الإلكتروني</label>
        <CustomInput
          type="text"
          placeholder="ادخل البريد الإلكتروني..."
          value={email}
          setValue={setEmail}
          customStyle="mt-5 mb-10"
          isRequired={true}
        />
        <label className="text-text-2-color font-bold">كلمة المرور</label>
        <CustomInput
          type="password"
          placeholder="ادخل كلمة المرور..."
          value={password}
          setValue={setPassword}
          customStyle="mt-5 mb-10"
          isRequired={true}
        />
        <CustomButton title="تسجيل الدخول" customStyle="mt-5 p-5" isLoading={isLoading} />
      </div>
    </div>
    </form>
  );
}
