
export default function CustomDropdown({ options, customStyle, style, setValue, listName, isRequired, isControlled, handleControlledInputChange, inputName, isDisabled, defaultValue }) {
  const handleChange = (e) => {
    if(!isControlled){
      const selectedKey = e.target.value;
      setValue(selectedKey);
    if(listName === "countryCodes"){
      const selectedValue = options[e.target.value].key;
      setValue(selectedValue);
    }else if(listName === "nationalities" || listName === "regions" || listName === "income"){
      setValue(selectedKey);
    }
    }else{
      handleControlledInputChange(e)
    }
    
  }
  return (
    <select
    disabled={isDisabled}
      name={inputName} className={`${customStyle} text-text-2-color disabled:bg-ice-color w-full p-4 rounded-full outline
    outline-offset-1 outline-2 outline-input-border-color
    focus:outline-primary-color placeholder-text-3-color`}
    defaultValue={defaultValue}
    style={style}
    onChange={handleChange}
    required={isRequired}
    >
      {
        listName === "countryCodes" 
        ? Object.entries(options).map(([key, value]) => {
          return <option key={key} value={key}>{value.key}</option>
        }) : listName === "nationalities" 
        ? Object.entries(options).map(([key, value]) => {
          return <option key={key} value={key}>{value}</option>
        }) : listName === "regions" 
        ? Object.entries(options.SA).map(([key, value]) => {
          return <option key={key} value={key}>{value}</option>
        }) : listName === "income" 
        ? Object.entries(options).map(([key, value]) => {
          return <option key={key} value={key}>{value}</option>
        }) : listName === "banksNames" 
        ? Object.entries(options).map(([key, value]) => {
          return <option key={key} value={key}>{value}</option>
        })
        : Object.entries(options).map(([key, value]) => {
          return <option key={key} value={key}>{value}</option>
        })
      }
    </select>
  );
}
