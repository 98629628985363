import { collection, getDocs, query, where } from "firebase/firestore";
import { firestore } from "../../config/firebase";


const getInvestmentsByCampaignId = async (campaignId, status) => {
    const investmentsCollection = collection(firestore, "investments");
    const q = query(investmentsCollection, where("campaignId", "==", campaignId), where("status", "==", status))

    const querySnapshot = await getDocs(q);
    const investments = querySnapshot.docs.map((data) => {
        var invObj = data.data();
        invObj['id'] = data.id;
        return invObj
    });

    return investments;
}

export default getInvestmentsByCampaignId;