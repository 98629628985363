import { collection, getDocs, query } from "firebase/firestore";
import { firestore } from "../../config/firebase";

const getUsers = async () => {
    const usersCollection = collection(firestore, "users");
    const q = query(usersCollection);
    const querySnapshot = await getDocs(q);

    const usersData = querySnapshot.docs.map((data) => {
        var userObj = data.data();
        userObj['id'] = data.id;
        return userObj
    });
    
    return usersData;
}
export default getUsers;