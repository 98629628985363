import React, { useState } from 'react'
import CustomDropdown from '../../../components/CustomDropdown';
import CustomInput from '../../../components/CustomInput';
import CustomButton from '../../../components/CustomButton';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import notify from '../../../utils/notify';
import { COUNTRY_KEYS } from '../../../helpers/country_keys';
import updateInvestmentPromo from '../../../lib/investment_promos/updateInvestmentPromo';

export default function EditInvestmentPromoForm({ promoInfo }) {
    const navigate = useNavigate();
  const [codeValue, setCodeValue] = useState(promoInfo.code);
  const [codeOwner, setCodeOwner] = useState(promoInfo.owner);
  const [countryCode, setCountryCode] = useState(
    promoInfo.phone.substring(0, 4)
  );
  const [phoneNumber, setPhoneNumber] = useState(
    promoInfo.phone.substring(4, promoInfo.phone.length)
  );
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await updateInvestmentPromo(promoInfo.id, {
        code: codeValue,
        owner: codeOwner,
        phone: `${countryCode}${phoneNumber}`,
      });
      navigate(0);
    } catch (error) {
      setIsLoading(false);
      notify("ERROR", "حصل خطأ.");
    }
    setIsLoading(false);
  };
  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <ToastContainer />
      <div className="w-96">
        <div>
          <label className="text-text-2-color font-bold">الكود</label>
          <CustomInput
            type="text"
            value={codeValue}
            setValue={setCodeValue}
            placeholder="ينصح بكتابة كود قصير ومعبر عن المالك مثال: MUATH3904"
            customStyle="mt-5 mb-5"
            isRequired={true}
          />
        </div>
        <div>
          <label className="text-text-2-color font-bold">اسم مالك الكود</label>
          <CustomInput
            type="text"
            value={codeOwner}
            setValue={setCodeOwner}
            placeholder="ادخل الاسم الكامل لمالك الكود..."
            customStyle="mt-5 mb-5"
            isRequired={true}
          />
        </div>
        <div>
          <label className="text-text-2-color font-bold">رقم الجوال</label>
          <div className="flex gap-5 ">
            <CustomDropdown
              options={COUNTRY_KEYS}
              customStyle="mt-5 mb-5"
              style={{ maxWidth: 100 }}
              defaultValue={countryCode}
              setValue={setCountryCode}
              listName="countryCodes"
              isRequired={true}
            />
            <CustomInput
              type="number"
              value={phoneNumber}
              setValue={setPhoneNumber}
              placeholder="5XXXXXXXX"
              customStyle="mt-5 mb-5 w-full"
              isRequired={true}
            />
          </div>
        </div>
      </div>
      <CustomButton title="حفظ" customStyle="p-5 mt-10" isLoading={isLoading} />
    </form>
  );
}
