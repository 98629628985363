import React from 'react'

export default function CustomTextArea({ placeholder, value, setValue, customStyle, isRequired, isDisabled }) {
  const handleInput = (e) => {
    setValue(e.target.value)

  }
  return (
    <textarea 
    value={value}
    onChange={handleInput}
    required={isRequired}
    disabled={isDisabled}
    className={`${customStyle} text-text-2-color resize-none w-full p-4 rounded-3xl outline
      outline-offset-1 outline-2 outline-input-border-color
      focus:outline-primary-color placeholder-text-3-color disabled:bg-ice-color disabled:text-text-2-color`}
      placeholder={placeholder}
    />
  )
}
