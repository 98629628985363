import { firestore } from "../../config/firebase";
import { addDoc, collection } from "firebase/firestore";

const createInvestment = async (obj) => {
  const investmentsCollection = collection(firestore, "investments");
  try{
    await addDoc(investmentsCollection, obj);
  }catch(e){
    console.error("Cannot create campaign: ", e)
  }
};

export default createInvestment;
