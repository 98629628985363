import { firestore } from "../../config/firebase";
import { addDoc, collection } from "firebase/firestore";

const createReport = async (obj) => {
  const reportsCollection = collection(firestore, "reports");
  try{
    await addDoc(reportsCollection, obj);
  }catch(e){
    console.error("Error creating user: ", e)
  }
};

export default createReport;
