import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/PageTitle";
import CustomButton from "../../components/CustomButton";
import getRegistrationPromoById from "../../lib/registration_promos/getRegistrationPromoById";
import PageLoader from "../../components/PageLoader";
import getRegistrationPromosUsageByCodeId from "../../lib/registration_promos/getRegistrationPromosUsageByCodeId";
import getUserById from "../../lib/users/getUserById";
import { NATIONALITIES } from "../../helpers/nationalities";
import { REGIONS } from "../../helpers/regions";
import { INCOME } from "../../helpers/income";
import { formatDateNow } from "../../utils/formatDateNow";
import { exportExcelData } from "../../utils/exportExcelData";

export default function RegistrationPromoUsages() {
  const { promoId } = useParams();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [promo, setPromo] = useState({});
  const [promosUsage, setPromosUsage] = useState([]);
  const [users, setUsers] = useState([]);
  const tableHeaders = [
    "اسم المستخدم",
    "رقم الجوال",
    "المدينة",
    "الجنسية",
    "الدخل الشهري",
    "حساب بنكي",
    "التاريخ",
  ];
  useEffect(() => {
    const fetchData = async () => {
      // Promo info
      const fetchedPromo = await getRegistrationPromoById(promoId);
      setPromo(fetchedPromo);

      // Promo usage
      const fetchedPromosUsage = await getRegistrationPromosUsageByCodeId(promoId);
      setPromosUsage(fetchedPromosUsage);

      // Promo Users
      const tempUsers = [];
      for(var i = 0; i < fetchedPromosUsage.length; i++){
        const userById = await getUserById(fetchedPromosUsage[i].userId);
        tempUsers.push(userById);
      }

      setUsers(tempUsers);
      setIsPageLoading(false);
    };
    fetchData();
  }, [promoId]);

  const handleOnExport = () => {
    // temp user data to map regions, nationalities, income
    let usersData = [];
    for (var i = 0; i < users.length; i++) {
      const userObj = {
        "اسم المستخدم": users[i].name,
        "رقم الجوال": users[i].phone,
        "المدينة": REGIONS["SA"][users[i].city],
        "الجنسية": NATIONALITIES[users[i].nationality],
        "الدخل الشهري": INCOME[users[i].income],
        "حساب بنكي": users[i].bankAccounts.length > 0 ? "يوجد" : "لا يوجد",
        "التاريخ": promosUsage[i].createdAt.toDate().toISOString().split("T")[0],
      };
      usersData.push(userObj);
    }
    const now = formatDateNow();
    exportExcelData(usersData, "Usage", `${promo.code}_Usage_${now}.xlsx`);
  };
  return isPageLoading 
  ? (<PageLoader />)
  : (
    <div>
      <Navbar />
      <PageTitle title={`سجل الكود الترويجي للمسجلين ${promo.code}`} />
      <div className="bg-ice-color p-5 text-center rounded-lg mt-5 mb-10">
        <p className="text-text-1-color font-bold text-lg mb-5">
          عدد استخدامات الكود
        </p>
        <p className="text-primary-color text-2xl">{promosUsage.length}</p>
      </div>
      <div className="flex flex-row justify-between items-center mb-16">
        <PageTitle title="السجل" />
        <div className="w-60">
          <CustomButton
            title="استخراج اكسل"
            customStyle="p-2 mb-5"
            handleClick={handleOnExport}
          />
        </div>
      </div>
      <div className="overflow-x-auto">
      <table className="w-full text-sm">
        <thead className="text-xs text-white uppercase bg-primary-color font-bold text-center">
          <tr>
            {tableHeaders.map((header, index) => {
              return (
                <th scope="col" className="px-6 py-3" key={index}>
                  {header}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="text-center">
          {users.map((column, index) => {
            return (
              <tr key={index} className="bg-white text-text-1-color font-semibold odd:bg-white even:bg-ice-color">
                {
                  <>
                  <td className="px-6 py-4">{column.name}</td>
                  <td className="px-6 py-4" dir="ltr">{column.phone}</td>
                  <td className="px-6 py-4">{REGIONS["SA"][column.city]}</td>
                  <td className="px-6 py-4">{NATIONALITIES[column.nationality]}</td>
                  <td className="px-6 py-4">{INCOME[column.income]}</td>
                  {column.bankAccounts.length > 0 ? (
                    <td className="px-6 py-4">يوجد</td>
                  ) : (
                    <td className="px-6 py-4">لا يوجد</td>
                  )}
                  <td className="px-6 py-4">{promosUsage[index].createdAt.toDate().toISOString().split("T")[0]}</td>
                  </>
                }
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
    </div>
  );
}
