import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from "../../components/Navbar";
import PageTitle from "../../components/PageTitle";
import CustomButton from "../../components/CustomButton";
import getLogsByCampaignId from "../../lib/pre_active_logs/getLogsByCampaignId";
import getUserById from "../../lib/users/getUserById";
import getCampaignById from "../../lib/campaigns/getCampaignById";
import PageLoader from "../../components/PageLoader";
import { formatDateNow } from "../../utils/formatDateNow";
import { exportExcelData } from "../../utils/exportExcelData";

export default function ReservationRecords() {
  const { campaignId } = useParams();
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [preActiveLogs, setPreActiveLogs] = useState([]);
  const [usersLogs, setUsersLogs] = useState([]);
  const [currentCampaign, setCurrentCampaign] = useState({});
  const [totalRequestedShares, setTotalRequestedShares] = useState(0);
  const [totalReservationsAmount, setTotalReservationsAmount] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      // fetch logs
      const fetchedLogs = await getLogsByCampaignId(campaignId);
      const fetchedCampaign = await getCampaignById(campaignId);

      let tempUsers = [];
      let totalReq = 0;
      let totalResAmount = 0;

      for(let i = 0; i < fetchedLogs.length; i++){
        var user = await getUserById(fetchedLogs[i].userId);
        totalReq += fetchedLogs[i].requestedSharesNumber;
        totalResAmount += (fetchedCampaign.sharePrice * fetchedLogs[i].requestedSharesNumber);
        tempUsers.push(user);
      }

      // assign data
      setUsersLogs(tempUsers);
      setPreActiveLogs(fetchedLogs);
      setCurrentCampaign(fetchedCampaign);
      setTotalRequestedShares(totalReq);
      setTotalReservationsAmount(totalResAmount)
      
      setIsPageLoading(false);
    };
    fetchData();
  }, []);
  const tableHeaders = [
    "اسم المستخدم",
    "رقم الجوال",
    "عدد الوحدات المحجوزة",
    "قيمة الوحدة (ريال)",
    "قيمة حجز الإستثمار (ريال)",
    "تاريخ الحجز",
    "ملاحظات للحجز",
  ];
  const handleOnExport = () => {
    // temp user data to map regions, nationalities, income
    let usersData = [];
    for (var i = 0; i < preActiveLogs.length; i++) {
      const userObj = {
        "اسم المستخدم": usersLogs[i].name,
        "رقم الجوال": usersLogs[i].phone,
        "عدد الوحدات المحجوزة": preActiveLogs[i].requestedSharesNumber,
        "قيمة الوحدة (ريال)": currentCampaign.sharePrice.toLocaleString(),
        "قيمة حجز الإستثمار (ريال)": (currentCampaign.sharePrice * preActiveLogs[i].requestedSharesNumber).toLocaleString(),
        "تاريخ الحجز": preActiveLogs[i].createdAt.toDate().toISOString().split("T")[0],
        "ملاحظات للحجز": preActiveLogs[i].notes,
      };
      usersData.push(userObj);
    }
    const now = formatDateNow();
    exportExcelData(usersData, "Reservations", `Reservations${now}.xlsx`);
  };
  return isPageLoading ? (
    <PageLoader />
  ) : (
    <div>
      <Navbar />
      <div className="tablet:flex tablet:flex-row gap-10">
        <div className="bg-ice-color p-5 text-center rounded-lg mt-5 mb-10 w-full">
          <p className="text-text-1-color font-bold text-lg mb-5">
            عدد الحجوزات الكلي
          </p>
          <p className="text-primary-color text-2xl">{preActiveLogs.length}</p>
        </div>
        <div className="bg-ice-color p-5 text-center rounded-lg mt-5 mb-10 w-full">
          <p className="text-text-1-color font-bold text-lg mb-5">
            عدد الوحدات المحجوزة
          </p>
          <p className="text-primary-color text-2xl">{totalRequestedShares}</p>
        </div>
        <div className="bg-ice-color p-5 text-center rounded-lg mt-5 mb-10 w-full">
          <p className="text-text-1-color font-bold text-lg mb-5">
            قيمة الحجوزات (الإستثمارات)
          </p>
          <p className="text-primary-color text-2xl">{totalReservationsAmount.toLocaleString()} ريال</p>
        </div>
      </div>
      <PageTitle title={currentCampaign.title} />
      <div className="flex flex-row justify-between items-center mb-16 mt-10">
        <p className="text-text-1-color font-bold text-base my-5">
          سجل الحجوزات
        </p>
        <div className="w-60">
          <CustomButton
            title="استخراج اكسل"
            customStyle="p-2 mb-5"
            handleClick={handleOnExport}
          />
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="w-full text-sm">
          <thead className="text-xs text-white uppercase bg-primary-color font-bold text-center">
            <tr>
              {tableHeaders.map((header, index) => {
                return (
                  <th scope="col" className="px-6 py-3" key={index}>
                    {header}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className="text-center">
            {preActiveLogs.map((column, index) => {
              return (
                <tr
                  key={index}
                  className="bg-white text-text-1-color font-semibold odd:bg-white even:bg-ice-color"
                >
                  {
                    <>
                      <td className="px-6 py-4">{usersLogs[index].name}</td>
                      <td className="px-6 py-4" dir="ltr">{usersLogs[index].phone}</td>
                      <td className="px-6 py-4">{column.requestedSharesNumber}</td>
                      <td className="px-6 py-4">{currentCampaign.sharePrice.toLocaleString()}</td>
                      <td className="px-6 py-4">{(currentCampaign.sharePrice * column.requestedSharesNumber).toLocaleString()}</td>
                      <td className="px-6 py-4">{column.createdAt.toDate().toISOString().split("T")[0]}</td>
                      <td className="px-6 py-4">{column.notes}</td>
                    </>
                  }
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}
