import { collection, getDocs, orderBy, query, where } from "firebase/firestore";
import { firestore } from "../../config/firebase";

const getReportsByCampaignId = async (campaignId) => {
  const reportsCollection = collection(firestore, "reports");
  const q = query(
    reportsCollection,
    where("campaignId", "==", campaignId),
    where("status", "==", "ACTIVE"),
    orderBy("createdAt", "desc")
  );

  const querySnapshot = await getDocs(q);
  const reports = querySnapshot.docs.map((data) => {
    var repObj = data.data();
    repObj["id"] = data.id;
    return repObj;
  });

  return reports;
};

export default getReportsByCampaignId;
