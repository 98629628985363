import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { firestore } from "../../config/firebase";

const getNotificationsByType = async (type) => {
  const notificationsCollection = collection(firestore, "notifications");
  const q = query(notificationsCollection, orderBy("createdAt", "desc"));
  const querySnapshot = await getDocs(q);

  const notificationsData = querySnapshot.docs.map((data) => {
    if (data.data().data.type === type) {
      var notiObj = data.data();
      notiObj["id"] = data.id;
      return notiObj;
    }else{
      return null;
    }
  }).filter(Boolean);

  return notificationsData;
};
export default getNotificationsByType;
