import React from "react";

export default function InvestmentStatus({ status }) {
  const tileStyle = status === "APPROVED" 
  ? "bg-green-200" 
  : status === "PENDING" 
  ? "bg-gray-300" 
  : "bg-red-200";

  const textColor = status === "APPROVED" 
  ? "text-success-color" 
  : status === "PENDING" 
  ? "text-gray-500" 
  : "text-danger-color";
  return (
    <div className={`px-4 py-2 rounded-full ${tileStyle}`}>
      <p className={textColor}>
        {status === "APPROVED" 
  ? "تمت الموافقة" 
  : status === "PENDING" 
  ? "بانتظار الموافقة" 
  : status === "REJECTED" ? "مرفوض" : "محذوف"}
      </p>
    </div>
  );
}
