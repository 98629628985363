// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Live DB Config
const firebaseConfig = {
  apiKey: "AIzaSyDVXLPqj7DabCh4N55hfarfJz_Va9x0Phw",
  authDomain: "qaswarah-ec4ec.firebaseapp.com",
  projectId: "qaswarah-ec4ec",
  storageBucket: "qaswarah-ec4ec.appspot.com",
  messagingSenderId: "617201730833",
  appId: "1:617201730833:web:065b8ed048757cb37af8b0",
  measurementId: "G-1YQ8NRMBYX"
};

// Test DB Config
// const firebaseConfig = {
//   apiKey: "AIzaSyCIr9ToBjhgQ4JR_es6y4B55_Yu4uX0HXE",
//   authDomain: "qaswarah-staging.firebaseapp.com",
//   projectId: "qaswarah-staging",
//   storageBucket: "qaswarah-staging.appspot.com",
//   messagingSenderId: "987930839963",
//   appId: "1:987930839963:web:b5876f62bbd3ec23dd6f3e",
//   measurementId: "G-WSCDVBJ6NN"
// };


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth();
export const firestore = getFirestore();
export const storage = getStorage();


export default app;