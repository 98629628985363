import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar";
import DashboardItem from "./components/DashboardItem";
import DashboardButton from "./components/DashboardButton";
import PageTitle from "../../components/PageTitle";
import { useNavigate } from "react-router-dom";
import getCampaignsCount from "../../lib/campaigns/getCampaignsCount";
import getInvestmentsCount from "../../lib/investments/getInvestmentsCount";
import getUsersCount from "../../lib/users/getUsersCount";
import getCampaigns from "../../lib/campaigns/getCampaigns";
import PageLoader from "../../components/PageLoader";

export default function HomePage() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [usersCount, setUsersCount] = useState(0);
  const [activeCampaignsCount, setActiveCampaignsCount] = useState(0);
  const [completedCampaignsCount, setCompletedCampaignsCount] = useState(0);
  const [pendingInvestmentsCount, setPendingInvestmentsCount] = useState(0);
  const [overallAvgTime, setOverallAvgTime] = useState(null);
  const [totalInvestments, setTotalInvestments] = useState(0);
  

  const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const totalMinutes = Math.floor(totalSeconds / 60);
    const totalHours = Math.floor(totalMinutes / 60);

    const days = Math.floor(totalHours / 24);
    const hours = totalHours % 24;
    const minutes = totalMinutes % 60;

    return `${days}d ${hours}h ${minutes}m`;
  };

  useEffect(() => {
    const fetchInsights = async () => {
      const users = await getUsersCount();
      const activeCampaigns = await getCampaignsCount("ACTIVE");
      const completedCampaigns = await getCampaignsCount("COMPLETED");
      const pendingInvestments = await getInvestmentsCount("PENDING");
      const campaignsData = await getCampaigns();

      const tempArr = [];
      var total = 0;
      
      campaignsData.forEach((data) => {
        if(data.createdAt && data.closedAt){
          const createdAt = data.createdAt.toDate();
          const closedAt = data.closedAt.toDate();
          const timeDifference = closedAt.getTime() - createdAt.getTime();
          tempArr.push(timeDifference);
        }
        
        total += data.investmentMaxAmount
      })

      const totalAvgTime = tempArr.reduce((acc, cur) => acc + cur, 0) / tempArr.length;
      

      setUsersCount(users);
      setActiveCampaignsCount(activeCampaigns);
      setCompletedCampaignsCount(completedCampaigns);
      setPendingInvestmentsCount(pendingInvestments);
      setOverallAvgTime(totalAvgTime);
      setTotalInvestments(total);
      setIsLoading(false);
    }

    fetchInsights();
  }, [])
  return isLoading 
  ? <PageLoader />
  : (
    <div>
      <Navbar />
      <div className="flex flex-row items-center justify-between mb-10">
        <PageTitle title="الأرقام والتحاليل" />
        <div className="h-1 bg-text-3-color rounded-full grow mr-5"></div>
      </div>
      <div className="mb-20 grid gap-10 desktop:grid-cols-4 tablet:grid-cols-3 mobile:grid-cols-2">
        <DashboardItem title="عدد المستخدمين" value={usersCount.toLocaleString()} />
        <DashboardItem title="عدد الفرص المكتملة" value={completedCampaignsCount.toLocaleString()} />
        <DashboardItem title="عدد الفرص المتاحة" value={activeCampaignsCount.toLocaleString()} />
        <DashboardItem title="الطلبات الجديدة" value={pendingInvestmentsCount.toLocaleString()} />
        <DashboardItem title="متوسط وقت إغلاق الفرص" value={formatTime(overallAvgTime)} />
        <DashboardItem title="قيمة الفرص" value={`${totalInvestments.toLocaleString()} ريال`} />
      </div>
      <div className="flex flex-row items-center justify-between mb-10">
        <PageTitle title="الإجراءات والتحكم" />
        <div className="h-1 bg-text-3-color rounded-full grow mr-5"></div>
      </div>
      <div className="mb-20 grid gap-10 grid-cols-2">
        <DashboardButton title="إدارة المستخدمين" handleClick={() => navigate('/users_management')} />
        <DashboardButton title="إدارة الفرص الإستثمارية" handleClick={() => navigate('/opportunities_management')} />
        <DashboardButton title="إدارة المستثمرين" handleClick={() => navigate('/investors_management')} />
        <DashboardButton title="إدارة الأكواد الترويجية للمسجلين" handleClick={() => navigate('/registration_promos')} />
        <DashboardButton title="إدارة الأكواد الترويجية الإستثمارية" handleClick={() => navigate('/investment_promos')} />
      </div>
    </div>
  );
}
