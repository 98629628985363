import React from "react";

export default function DashboardButton({ title, handleClick }) {
  return (
    <button onClick={handleClick} className="px-10 py-20 bg-box-color rounded-2xl text-text-1-color text-xl font-bold text-start w-full">
        {title}
        <div className="h-1 w-full bg-text-1-color relative top-20 rounded-full"></div>
      </button>
  );
}
